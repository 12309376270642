/**
 * Author: Felipe Emidio, ??/??
 * Edited by: João Lucas, Nov/2019
 * Edited by: Henrique, Dez/2019
 */

import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import withWidth from '@material-ui/core/withWidth';
import { Submit } from '../form';
import Form from '../Form';
import Step from './Step';

const ShowInfoOpts = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  DECIDE: 'DECIDE',
};

function doNothing() {
  return null;
}

function createHandleChange(params) {
  const {
    index,
    expanded,
    maxIndex,
    unlockAll,
    anyErrors,
    showErrors,
    setExpanded,
    onChangeStep,
    communicating,
  } = params;

  return (event, isExpanded) => {
    if ((index > maxIndex && !unlockAll) || communicating) {
      return;
    }

    if (unlockAll) {
      onChangeStep(expanded, index);
      setExpanded(index);
    }

    if (index === expanded) {
      onChangeStep(expanded, null);
      setExpanded(-1);
    }

    const show = index >= expanded && anyErrors;
    showErrors(show);
    if (!show && isExpanded) {
      onChangeStep(expanded, index);
      setExpanded(index);
    }
  };
}

function validateAllSteps(content) {
  return {
    id: 'validateAllSteps',
    validator: (data) => {
      let allValidators = Map();

      content.forEach((stepProps) => {
        const { validator } = stepProps;
        const validation = validator && validator(data);
        if (validation) {
          allValidators = allValidators.merge(validation);
        }
      });
      return allValidators;
    },
  };
}

function getValidator(currentProps, content, isLast, unlockAll) {
  if (!currentProps && !unlockAll) {
    return doNothing;
  }
  if (isLast || unlockAll) {
    return validateAllSteps(content);
  }
  return currentProps.validator || doNothing;
}

function createUpdateShowInfo(params) {
  const { setShowInfo, expanded, content } = params;
  return (opt) => {
    if (opt === ShowInfoOpts.DECIDE) {
      const { infoProps } = content[expanded];
      setShowInfo(expanded, infoProps ? ShowInfoOpts.SHOW : ShowInfoOpts.HIDE);
    } else {
      setShowInfo(expanded, opt);
    }
  };
}

function checkExistsErrorsInAnyStep(count, hasError) {
  for (let i = 0; i < count; i++) {
    if (hasError(i)) {
      return true;
    }
  }
  return false;
}

function useState(editMode) {
  const [maxIndex, setMaxIndex] = React.useState(0);
  const [done, setDone] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState({});
  const [lastExpanded, setLastExpanded] = React.useState(null);
  const [expanded, setExpanded] = React.useState(editMode ? -1 : 0);

  return {
    done,
    expanded,
    maxIndex,
    setMaxIndex,
    setExpanded,
    lastExpanded,
    setLastExpanded,
    setDone: () => setDone(true),
    showInfo: (index) => showInfo[index] || ShowInfoOpts.DECIDE,
    setShowInfo: (index, value) => setShowInfo({ ...showInfo, [index]: value }),
  };
}

function useExpand(expanded, content, updateShowInfo) {
  React.useEffect(() => {
    if (expanded >= 0) {
      const { onExpand } = content[expanded];
      if (onExpand) {
        onExpand();
      }
      updateShowInfo(ShowInfoOpts.DECIDE);
    }
  }, [expanded]);
}

function AccordionForm(props) {
  const {
    disableSubmit,
    communicating,
    innerCollapse,
    onChangeStep,
    isSubmiting,
    successStep,
    showErrors,
    submitText,
    hideSubmitButton,
    unlockAll,
    hasError,
    collapse,
    editMode,
    doSubmit,
    noMargin,
    classes,
    content,
    width,
    view,
    id,
  } = props;

  const {
    done,
    setDone,
    expanded,
    maxIndex,
    showInfo,
    setExpanded,
    setMaxIndex,
    setShowInfo,
    lastExpanded,
    setLastExpanded,
  } = useState(editMode);
  const shouldCollapse = collapse || (successStep && innerCollapse);
  const anyErrors = checkExistsErrorsInAnyStep(content.length, hasError);

  // Adiciona o step de sucesso caso collapse seja verdadeiro.
  if (shouldCollapse && successStep) {
    // TODO a linha a seguir é confusa, pois para renderizar cada
    //      uma das seções, é feito um 'content.slice(0, content.length - 1)',
    //      ou seja, o valor é inserido no final do array e, na hora,
    //      de ser utilizado, é removido com uma custosa cópia de
    //      array.
    content.push(successStep);
  }

  const updateShowInfo = createUpdateShowInfo({
    setShowInfo,
    showInfo,
    expanded,
    content,
  });

  // Chama onExpand ao mudar de step.
  useExpand(expanded, content, updateShowInfo);
  // Atualiza o expansível aberto ao executar o collapse.
  React.useEffect(() => {
    if (shouldCollapse && successStep) {
      setExpanded(content.length - 1);
    }
  }, [shouldCollapse]);

  const isMobile = ['xs', 'sm'].includes(width);
  const lastIdx = content.length - 1;
  const lastPanel = content[lastIdx];
  const currentPanelProps =
    expanded >= 0 ? content[expanded] : content[lastExpanded];

  const stepProps = {
    hideInfo: () => updateShowInfo(ShowInfoOpts.HIDE),
    maxIndex: unlockAll ? content.length : maxIndex,
    hideSubmitButton: hideSubmitButton || shouldCollapse,
    done: unlockAll || done,
    setLastExpanded,
    communicating,
    setMaxIndex,
    isSubmiting,
    setExpanded,
    showErrors,
    setDone,
    submitText,
    unlockAll,
    isMobile,
    doSubmit,
    noMargin,
    classes,
  };

  return (
    <div style={{ width: '100%' }}>
      <Form
        id={id}
        view={view}
        validator={getValidator(
          currentPanelProps,
          content,
          lastIdx === expanded,
          unlockAll,
        )}
      >
        <Collapse in={!shouldCollapse}>
          {content.slice(0, content.length - 1).map((panelData, index) => {
            const { id } = panelData;

            return (
              <Step
                {...stepProps}
                key={id}
                index={index}
                isLast={false}
                editMode={editMode}
                panelData={panelData}
                disableSubmit={disableSubmit}
                isExpanded={index === expanded}
                hasValidationError={hasError(index)}
                showInfo={showInfo(index) === ShowInfoOpts.SHOW}
                handleChange={createHandleChange({
                  unlockAll: unlockAll || editMode,
                  setLastExpanded,
                  communicating,
                  lastExpanded,
                  onChangeStep,
                  setExpanded,
                  showErrors,
                  anyErrors,
                  expanded,
                  maxIndex,
                  index,
                })}
              />
            );
          })}
        </Collapse>
        <Step
          {...stepProps}
          isLast
          index={lastIdx}
          key={lastPanel.id}
          editMode={editMode}
          panelData={lastPanel}
          collapse={shouldCollapse}
          isExpanded={expanded === lastIdx}
          disableSubmit={disableSubmit || anyErrors}
          handleChange={createHandleChange({
            unlockAll: unlockAll || editMode,
            setLastExpanded,
            index: lastIdx,
            lastExpanded,
            onChangeStep,
            setExpanded,
            showErrors,
            anyErrors,
            expanded,
            maxIndex,
          })}
        />
      </Form>
    </div>
  );
}

AccordionForm.defaultProps = {
  id: 'form',
  invalidateCache: null,
  doSubmit: null,
  submit: Submit(),
  disableSubmit: false,
  collapse: null,
  noMargin: false,
  editMode: false,
  submitText: 'salvar',
  hideSubmitButton: false,
  unlockAll: false,
  successStep: null,
  onChangeStep: () => null,
};

AccordionForm.propTypes = {
  /**
   * id para identificação do formulário.
   */
  id: PropTypes.string,
  /**
   * Um objeto do tipo `Submit` que descreve o
   * processo de submissão dos dados do formulário.
   * A 'view' da 'request' e função de invalidação
   * de cache são configuradas automaticamente pela
   * FormView. A 'view' será a mesma passada nas
   * `formProps`.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  submit: PropTypes.instanceOf(Submit),

  /** Texto que aparece na ultima step para salvar */
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /** Omite o botão de submit da última step */
  hideSubmitButton: PropTypes.bool,

  /** Deixa as steps justapostas */
  noMargin: PropTypes.bool,

  /** Destrava todas as steps (foi pensando em ser usado para telas
   * de edição) */
  unlockAll: PropTypes.bool,

  /** Modo de edição (Cada step invoca o submit) */
  editMode: PropTypes.bool,

  /**
   * Callback chamada ao abrir ou fechar uma step.
   * Recebe 2 parâmetros, `prevStep` e `nextStep` que são respectivamente
   * a step antiga e a nova step.
   */
  onChangeStep: PropTypes.func,

  /**
   * Função chamada quando a submissão do formulário
   * retorna um status de sucesso (200 OK). Usada
   * para invalidar dados armazenados no frontend
   * que possam ter sido afetados no backend pela
   * submissão do formulário. Em geral os dados que
   * alimentam a tela de listagem que antecede o
   * formulário sempre devem ser invalidados.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  invalidateCache: PropTypes.func,

  /**
   * Indica se os n-1 paneis expansiveis serão omitidos.
   */
  collapse: PropTypes.bool,

  /**
   * Flag injetada pelo mapStateToProps ao FormView
   * para informar se a aplicação está enviando ou
   * esperando dados da API.
   */
  successStep: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    render: PropTypes.func,
    noTight: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    action: PropTypes.object,
    sideSubmit: PropTypes.func,
  }),

  /**
   * Conteúdo interno de cada tela expansivel.
   */
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      summary: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      render: PropTypes.func,
      validator: PropTypes.func,
      onExpand: PropTypes.func,
      infoProps: PropTypes.shape({
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
          .isRequired,
        ask: PropTypes.string,
        yesText: PropTypes.string,
        noText: PropTypes.string,
        onYes: PropTypes.func,
        onNo: PropTypes.func,
      }),
      noTight: PropTypes.bool,
      // eslint-disable-next-line react/forbid-prop-types
      action: PropTypes.object,
      sideSubmit: PropTypes.func,
    }),
  ).isRequired,

  /**
   * Flag para desabilitar o botão submit do formulário.
   */
  disableSubmit: PropTypes.bool,

  /**
   * Props injetadas via mapStateToProps e mapDispatchToProps
   */

  /**
   * Flag injetada pelo mapStateToProps ao FormView
   * para informar se a aplicação está enviando ou
   * esperando dados da API.
   */
  communicating: PropTypes.bool.isRequired,

  /**
   * TODO
   */
  isSubmiting: PropTypes.bool.isRequired,

  /**
   * TODO
   */
  hasError: PropTypes.func.isRequired,

  /**
   * TODO
   */
  innerCollapse: PropTypes.bool.isRequired,

  /**
   * Função gerada automaticamente no mapDispatchToProps
   * executa a descrição passada na prop `submit`.
   */
  doSubmit: PropTypes.func,

  /**
   * TODO
   */
  showErrors: PropTypes.func.isRequired,
};

export default withWidth()(AccordionForm);
