import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box } from '@material-ui/core';
import { Drawer } from '.';
import SimpleForm from '../templates/SimpleForm';
import { Submit } from './form';

function DrawerForm(props) {
  const {
    communicating,
    validator,
    children,
    onClose,
    loading,
    width,
    submit,
    title,
    open,
    view,
    id,
  } = props;

  return (
    <Drawer
      open={open}
      width={width}
      title={title}
      timeout={1000}
      onClose={onClose}
    >
      {loading ? (
        <Box display="flex" justifyContent="center" style={{ height: 150 }}>
          <CircularProgress />
        </Box>
      ) : (
        <SimpleForm
          paper={false}
          submit={submit}
          communicating={communicating}
          formProps={{ view, id, validator }}
        >
          {children}
        </SimpleForm>
      )}
    </Drawer>
  );
}

export default DrawerForm;

DrawerForm.defaultProps = {
  width: 'narrow',
  loading: false,
};

DrawerForm.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  communicating: PropTypes.bool.isRequired,
  submit: PropTypes.instanceOf(Submit).isRequired,
  width: PropTypes.oneOf(['narrow', 'medium', 'wide', 'default']),
};
