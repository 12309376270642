import { Collapse, List, ListItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import classNames from "classnames";
import Immutable from "immutable";
import React from "react";
import { Link } from "react-router-dom";

import ItemAvatar from "./ItemAvatar";
import ItemIcon from "./ItemIcon";
import ItemText from "./ItemText";
import getMenuIcon from "./get-menu-icon";

function styles(theme) {
  const u = theme.spacing(1);
  const { light: primary } = theme.palette.primary;

  return {
    button: {
      "&:focus": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "#E5EEF3",
      },
    },
    highlight: {
      fontWeight: 700,
      color: primary,
    },
    defaultColor: {
      color: theme.palette.primary.main,
    },
    depth2: {
      paddingRight: 0,
    },
    depth3: {
      paddingLeft: u * 4,
      paddingRight: 0,
    },
  };
}

/*
 * ---------------------------------------------------------
 *
 * Utilidades
 *
 * ---------------------------------------------------------
 */

function createHandleClick(params) {
  const { closedParallelAndDeeper, toggleSubMenu, onClick, props, depth, href, link, key } = params;
  const { switchDrawerFocus, onToggleDrawer, isLargeScreen, drawerOpen } = props;

  if (!link && !href) {
    return () => {
      toggleSubMenu(depth, key);
      if (onClick) {
        onClick();
      }
    };
  }

  return () => {
    closedParallelAndDeeper(depth);
    if (!isLargeScreen) {
      switchDrawerFocus(false);
      onToggleDrawer();
    } else {
      switchDrawerFocus(drawerOpen);
    }
    if (onClick) {
      onClick();
    }
  };
}

function getLinkProps(href, link) {
  if (link) {
    return { component: Link, to: link };
  }
  if (href) {
    return { component: "a", href };
  }
  return null;
}

function getClassName(depth, classes) {
  const classOptions = ["", "", classes.depth2, classes.depth3, ""];
  return classNames(classes.button, depth > 0 && classOptions[depth]);
}

function getIsCurrentPage(itemURL, applet, depth) {
  if (!itemURL) {
    return false;
  }

  const current = window.location.pathname;
  if (applet === "/") {
    return itemURL === applet && current === applet;
  }

  if (depth >= 1) {
    return current === itemURL;
  }
  const splitApplet = applet.split("/", 2)[1];
  return itemURL.startsWith(`/${splitApplet}`);
}

/*
 * ---------------------------------------------------------
 *
 * Funções de renderização
 *
 * ---------------------------------------------------------
 */

function renderMenuList(params) {
  const {
    props,
    depth,
    isOpen,
    parent,
    isFocused,
    itemProps,
    currentDepth,
    toggleSubMenu,
    noDefaultIcon,
    closedParallelAndDeeper,
  } = params;
  const { applet } = props;

  const { classes, open: drawerOpen, isLargeScreen } = props;
  const href = itemProps.get("href");
  const link = itemProps.get("link");
  const label = itemProps.get("label");
  const bold = itemProps.get("bold", false);
  const isUserName = itemProps.get("isUserName", false);
  const avatar = itemProps.get("avatar");
  const paramIcon = itemProps.get("icon");
  const submenu = itemProps.get("submenu");
  const onClick = itemProps.get("onClick");
  const avatarLetter = itemProps.get("avatarLetter");
  const secondaryLabel = itemProps.get("secondaryLabel");
  const itemURL = href || link;
  const key = `${label}-${parent}-${depth}`;
  const isSubmenuOpen = isOpen(depth, key);
  const open = isLargeScreen
    ? isSubmenuOpen && isLargeScreen && (drawerOpen || isFocused)
    : isSubmenuOpen;
  const selected = depth > 0 && depth === currentDepth;
  const hasSubmenu = submenu && submenu.size > 0;
  const isCurrentPage = getIsCurrentPage(itemURL, applet, depth);

  const icon =
    depth < 1
      ? getMenuIcon(paramIcon, Boolean(submenu), noDefaultIcon, open || isCurrentPage)
      : null;

  let expandMenuIcon = null;

  if (hasSubmenu) {
    expandMenuIcon = open ? (
      <ArrowDropUp className={classes.highlight} />
    ) : (
      <ArrowDropDown className={classes.defaultColor} />
    );
  }

  return (
    <React.Fragment key={key}>
      <ListItem
        button
        key={key}
        selected={selected}
        {...getLinkProps(href, link)}
        className={getClassName(depth, classes)}
        style={selected ? { backgroundColor: "#E5EEF3" } : null}
        onClick={createHandleClick({
          closedParallelAndDeeper,
          toggleSubMenu,
          onClick,
          props,
          depth,
          href,
          link,
          key,
        })}
      >
        {depth < 1 && icon && (
          <ItemIcon
            isCurrentPage={isCurrentPage}
            isSubmenuOpen={isSubmenuOpen}
            drawerOpen={drawerOpen}
            depth={depth}
            icon={icon}
          />
        )}
        {(avatar || avatarLetter) && (
          <ItemAvatar avatarLetter={avatarLetter} drawerOpen={drawerOpen} avatar={avatar} />
        )}
        <ItemText
          secondaryLabel={secondaryLabel}
          isCurrentPage={isCurrentPage}
          isSubmenuOpen={isSubmenuOpen}
          hasSubmenu={hasSubmenu}
          label={label}
          bold={bold}
          isUserName={isUserName}
          depth={depth}
        />
        {expandMenuIcon}
      </ListItem>
      {hasSubmenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {submenu
              .map((s) =>
                renderMenuList({
                  closedParallelAndDeeper,
                  depth: depth + 1,
                  toggleSubMenu,
                  noDefaultIcon,
                  currentDepth,
                  itemProps: s,
                  parent: key,
                  isFocused,
                  isOpen,
                  props,
                }),
              )
              .toArray()}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
}

/*
 * ---------------------------------------------------------
 *
 * Componente
 *
 * ---------------------------------------------------------
 */

function closedParallelAndDeeper(state, depth) {
  return {
    0: depth < 1 ? null : state[0],
    1: depth < 2 ? null : state[1],
    2: depth < 3 ? null : state[2],
    3: depth < 4 ? null : state[3],
  };
}

function useMenuState() {
  const [state, setState] = React.useState({});
  const keys = Object.keys(state);
  const currentDepth = Immutable.List(keys)
    .map((k) => (state[k] ? parseInt(k, 10) : -1))
    .takeWhile((k) => k >= 0)
    .reduce((acc, k) => (k > acc ? k : acc), 0);

  return {
    currentDepth: currentDepth + 1,
    isOpen: (depth, key) => key === state[depth],
    closedParallelAndDeeper: (depth) => setState(closedParallelAndDeeper(state, depth)),
    toggleSubMenu: (depth, id) => {
      const openned = state[depth];
      setState({ ...state, [depth]: openned === id ? null : id });
    },
  };
}

function DashboardMenu(props) {
  const {
    isFocused,
    noDefaultIcon = false,
    menu = Immutable.List([]),
    style,
    className,
    ...other
  } = props;

  const { closedParallelAndDeeper, toggleSubMenu, currentDepth, isOpen } = useMenuState();

  return (
    <List component="div" style={style} className={className}>
      {menu
        .map((item) =>
          renderMenuList({
            closedParallelAndDeeper,
            itemProps: item,
            toggleSubMenu,
            noDefaultIcon,
            currentDepth,
            props: other,
            parent: "",
            isFocused,
            depth: 0,
            isOpen,
          }),
        )
        .toArray()}
    </List>
  );
}

export default React.memo(withStyles(styles)(DashboardMenu));
