import React from 'react';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import qsession from '../../core/session/queries';
import DashboardContext from './DasboardContext';
import MenuDrawer from './MenuDrawer';
import RightPanel from './RightPanel';

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    zIndex: 1,
  },
};

function Dashboard(props) {
  const {
    menu,
    width,
    applet,
    classes,
    children,
    profileData,
    suspendSession,
  } = props;
  const isLargeScreen = width === 'lg' || width === 'xl';
  const [isOpen, setOpen] = React.useState(null);
  const drawerOpen = isOpen === null ? isLargeScreen : isOpen;
  const onToggleDrawer = React.useCallback(
    () => setOpen(!(isOpen === null ? isLargeScreen : isOpen)),
    [isOpen],
  );

  const userProfile = React.useMemo(
    () => ({
      numberOfOwnedOrgs: profileData.get('numOrgsAcesso'),
      organization: profileData.get('orgUltAcesso'),
      apelido: profileData.get('apelido', ''),
      profileType: profileData.get('perfil'),
      avatar: profileData.get('avatar', ''),
      name: profileData.get('nome', ''),
    }),
    [profileData],
  );

  return (
    <div className={classes.root}>
      <DashboardContext.Provider value={{ toggleDrawer: onToggleDrawer }}>
        <MenuDrawer
          suspendSession={suspendSession}
          onToggleDrawer={onToggleDrawer}
          isLargeScreen={isLargeScreen}
          userProfile={userProfile}
          open={drawerOpen}
          applet={applet}
          width={width}
          menu={menu}
        />
        <RightPanel drawerOpen={drawerOpen}>{children}</RightPanel>
      </DashboardContext.Provider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profileData: qsession.getUserProfile(state),
  };
}

const DashboardComponent = React.memo(
  connect(mapStateToProps, null)(Dashboard),
);

export default withWidth()(withStyles(styles)(DashboardComponent));
