import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

function styles() {
  return {
    enter: {
      opacity: 0,
      transform: 'translateX(-100%)',
    },
    enterBack: {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    enterActive: {
      opacity: 1,
      transform: 'translateX(0%)',
      transition: 'opacity 500ms, transform 500ms',
    },
    exit: {
      opacity: 1,
      transform: 'translateX(0%)',
    },
    exitActive: ({ isNext }) => ({
      height: 0,
      opacity: 0,
      transform: `translateX(${isNext ? '-100' : '100'}%)`,
      transition: 'opacity 500ms, transform 500ms',
    }),
  };
}

function Carousel(props) {
  const { children, index, isNext, classes } = props;
  return (
    <SwitchTransition>
      <CSSTransition
        key={index}
        timeout={{ appear: 1000, enter: 500, exit: 300 }}
        classNames={{
          enter: isNext ? classes.enterBack : classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive,
        }}
      >
        <div>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default React.memo(withStyles(styles)(Carousel));
