import { Box, Typography } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";

const orderStyles = (descriptionAlign) => {
  if (descriptionAlign === "top") {
    return {
      order: 1,
    };
  }
  return {
    order: 2,
  };
};
const chartOrderStyles = (descriptionAlign, height) => {
  if (descriptionAlign === "top") {
    return {
      order: 2,
      height: height || "12rem",
    };
  }
  if (descriptionAlign === "left") {
    return {
      order: 1,
      height: height || "12rem",
    };
  }
  return {
    order: 1,
    height: height || "12rem",
  };
};

export function DoughnutChart(props) {
  const {
    classes,
    title,
    labels,
    descriptionAlign,
    values,
    colors,
    hoverColors,
    description,
    mainValue,
    percentage,
    centerMessage,
    height,
  } = props;

  const allZero = values.every((value) => value === 0 || value === null || value === undefined);
  const data = allZero
    ? {
        labels: ["None"],
        datasets: [
          {
            data: [1],
            backgroundColor: ["#f0f0f0"], // Altere para a cor desejada
            hoverBackgroundColor: ["#f0f0f0"], // Altere para a cor desejada
          },
        ],
      }
    : {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
            hoverBackgroundColor: hoverColors,
          },
        ],
      };

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      {title && (
        <Box className={classes.title}>
          <Typography className={classes.titleText}>{title}</Typography>
        </Box>
      )}
      {description && (
        <Box className={classes.description} style={orderStyles(descriptionAlign)}>
          {description.map((item) => (
            <Box key={item.labelDescription} className={classes.descriptionText}>
              <div style={{ backgroundColor: item.dotColor }} className={classes.dot} />
              <span>{item.labelDescription}</span>
            </Box>
          ))}
        </Box>
      )}
      <div className={classes.doughnutContainer} style={chartOrderStyles(descriptionAlign, height)}>
        <Doughnut
          height={200}
          data={data}
          options={{
            maintainAspectRatio: false,
            tooltips: {
              enabled: !allZero,
              intersect: true,
            },
            legend: {
              display: false,
            },
            plugins: {
              labels: {
                render: () => {},
              },
            },
            hover: {
              mode: allZero ? null : "nearest",
            },
            cutoutPercentage: 70,
          }}
        />
        {values && !allZero && (
          <div className={classes.labelContainer}>
            <Typography
              style={{
                color: colors[0],
                opacity: 1,
                userSelect: "none",
              }}
              className={classes.label}
            >
              {mainValue && `${percentage % 1 === 0 ? percentage : percentage.toFixed(2)}%`}
            </Typography>
            <Typography
              style={{
                color: colors[0],
                opacity: 1,
                userSelect: "none",
              }}
              className={classes.labelMessage}
            >
              {centerMessage}
            </Typography>
          </div>
        )}
      </div>
    </Box>
  );
}
