import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Grid,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import React from "react";

import { StepContext } from "../form";
import ActionPanel from "./ActionPanel";
import InfoStep from "./InfoStep";
import Summary from "./Summary";

function styles(theme) {
  const u = theme.spacing(1);
  return {
    root: {
      margin: `${u * 2}px 0px`,
      boxShadow:
        " 0px 0.5px 5px 0px rgba(0,0,0,0.2)," +
        " 0px 0.5px 2px 0px rgba(0,0,0,0.14)," +
        " 0px 1px 1px -2px rgba(0,0,0,0.12)",

      "&:last-child": {
        marginBottom: 0,
      },
    },
    summaryPanel: {
      "&:hover": {
        backgroundColor: "#eee",
        transition: "background 200ms ease",
      },
    },
    expandedEdit: {
      margin: `${u * 2}px 0px`,
      "&:last-child": {
        marginBottom: u * 2,
      },
    },
    expanded: {
      "&:last-child": {
        marginBottom: 0,
      },
    },
    noMargin: {
      margin: 0,
    },
    grid: {
      paddingLeft: u,
    },
  };
}

function createSubmitButtonOnClick(params) {
  const {
    setExpanded,
    setMaxIndex,
    showErrors,
    maxIndex,
    doSubmit,
    editMode,
    hasError,
    setDone,
    isLast,
    index,
  } = params;

  const canSubmit = isLast || editMode;
  showErrors(hasError);
  if (hasError && !canSubmit && index < maxIndex) {
    setExpanded(index + 1);
    return;
  }
  if (canSubmit) {
    setDone();
    doSubmit();
    return;
  }

  setExpanded(index + 1);
  if (index >= maxIndex) {
    setMaxIndex(index + 1);
  }
}

function Step(props) {
  const {
    hasValidationError,
    disableSubmit,
    communicating,
    handleChange,
    setMaxIndex,
    setExpanded,
    isSubmiting,
    isExpanded,
    showErrors,
    submitText,
    hideSubmitButton,
    hideActions,
    panelData,
    unlockAll,
    hideInfo,
    noMargin,
    editMode,
    maxIndex,
    isMobile,
    showInfo,
    collapse,
    doSubmit,
    setDone,
    classes,
    isLast,
    index,
    done,
  } = props;

  const { id, render, action, infoProps, noTight, sideSubmit } = panelData;
  const callNext = () =>
    createSubmitButtonOnClick({
      hasError: hasValidationError,
      setExpanded,
      setMaxIndex,
      showErrors,
      editMode,
      maxIndex,
      doSubmit,
      setDone,
      isLast,
      index,
    });
  return (
    <StepContext.Provider value={{ step: index }}>
      <Accordion
        onChange={handleChange}
        classes={{
          root: classNames(classes.root, noMargin && !isExpanded && classes.noMargin),
          expanded: editMode ? classes.expandedEdit : classes.expanded,
        }}
        expanded={isExpanded}
      >
        <AccordionSummary
          expandIcon={index <= maxIndex || editMode ? <ExpandMoreIcon fontSize="large" /> : null}
          className={classNames(editMode && classes.summaryPanel)}
          aria-controls={`${id}bh-content`}
          id={`${id}bh-header`}
        >
          <Summary
            index={index}
            isMobile={isMobile}
            editMode={editMode}
            stepProps={panelData}
            isCurrentStep={isExpanded}
            communicating={communicating}
            isActive={unlockAll || isExpanded}
            hasValidationError={hasValidationError}
            isDone={done || index < maxIndex || collapse}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid className={classes.grid} container>
            <Grid container item xs={12} md={noTight ? 12 : 10}>
              {showInfo ? (
                <InfoStep
                  {...infoProps}
                  callNext={callNext}
                  onShowContent={hideInfo}
                  communicating={communicating}
                />
              ) : (
                render &&
                render({
                  isMobile,
                  submit: callNext,
                })
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions hideActions={hideActions}>
          <Collapse in={!showInfo}>
            <ActionPanel
              disableSubmit={disableSubmit}
              communicating={communicating}
              isSubmiting={isSubmiting}
              setMaxIndex={setMaxIndex}
              submitText={submitText}
              hideSubmitButton={hideSubmitButton}
              showErrors={showErrors}
              sideSubmit={sideSubmit}
              editMode={editMode}
              doSubmit={doSubmit}
              isMobile={isMobile}
              maxIndex={maxIndex}
              onClick={callNext}
              isLast={isLast}
              action={action}
              index={index}
            />
          </Collapse>
        </AccordionActions>
      </Accordion>
    </StepContext.Provider>
  );
}

export default withStyles(styles)(Step);
