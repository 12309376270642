import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../theme";

const useStyles = makeStyles({
  containedButton: {
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    height: "2.2rem",
    minWidth: "11rem",
    lineHeight: "20px",
    fontSize: ".8rem",
    fontWeight: "bold",
    borderRadius: "27px",
    background: colors.blueLight,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    transition: "all 500ms",

    "&:hover": {
      opacity: "0.8",
      background: colors.blueLight2,
    },

    "&:disabled": {
      opacity: "0.6",
      backgroundColor: "#aeaeae",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.35)",
    },
  },
  outlinedButton: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    height: "2.2rem",
    minWidth: "11rem",
    lineHeight: "20px",
    fontSize: ".8rem",
    fontWeight: "bold",
    borderRadius: "27px",
    color: colors.blueLight,
    border: `2px solid ${colors.blueLight}`,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    transition: "all 500ms",

    "&:hover": {
      opacity: "1.5",
      backgroundColor: "#edeaea",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.35)",
    },

    "&:disabled": {
      opacity: "0.6",
      backgroundColor: "#edeaea",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.35)",
    },
  },
});

export function ButtonRounded(props) {
  const { text, bordered, onClick, disabled } = props;

  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={bordered ? classes.outlinedButton : classes.containedButton}
    >
      {text}
    </Button>
  );
}
