import React from 'react';
import Grid from '@material-ui/core/Grid';
import Mask from './Mask';

function GridMask(props) {
  const {
    xs = 12,
    sm,
    md,
    lg,
    xl,
    style,
    color,
    titleColor,
    maskStyle,
    ...others
  } = props;
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} style={style}>
      <Mask
        color={color || 'greyDark'}
        titleColor={titleColor || 'default'}
        style={maskStyle}
        {...others}
      />
    </Grid>
  );
}

// @TODO Update: adicionar prop types

export default GridMask;
