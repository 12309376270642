import { Button, Grid } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
    width: "2rem",
    height: "2rem",
    padding: "0",
    minWidth: "0",
  },
}));
export function RoundedButton({ onClick, text, justifyContent }) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      justifyContent={justifyContent}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "1rem",
      }}
    >
      <Grid
        item
        xs={8}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>{text}</h2>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent,
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onClick}
          className={classes.button}
        >
          <ArrowForward fontSize="small" />
        </Button>
      </Grid>
    </Grid>
  );
}

RoundedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  justifyContent: PropTypes.string,
};

RoundedButton.defaultProps = {
  justifyContent: "flex-end",
  text: "",
};
