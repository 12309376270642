import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import storage from '../../core/storage/actions';
import qstorage from '../../core/storage/queries';
import qform from '../../core/components/form/queries';
import form from '../../core/components/form/actions';
import qcomm from '../../core/comm/queries';
import { Submit } from '../form';
import { insertInOnSuccess } from '../../core/util';
import Form from '../Form';
import AccordionForm from './AccordionForm';

function setCollapse(view, id, flag) {
  return storage.set(view, [`${id}@State`, 'collapse'], flag);
}

function getCollapse(state, view, id) {
  return qstorage.get(state, view, [`${id}@State`, 'collapse'], false);
}

function mapStateToProps(state, ownProps) {
  const { view, id = 'form', submit = Submit() } = ownProps;

  const resource = submit.getIn(['request', 'url']);

  return {
    communicating: qcomm.isCommunicating(state),
    innerCollapse: getCollapse(state, view, id),
    isSubmiting: qcomm.isSending(state, view, resource),
    hasError: (step) =>
      qform.hasRemoteValidations(state, view, id, step) ||
      qform.hasErrors(state, view, id, step),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { view, id = 'form', invalidateCache, submit = Submit() } = ownProps;
  let doSubmit = null;
  if (submit) {
    const s = insertInOnSuccess(submit, () => setCollapse(view, id, true))
      .setIn(['request', 'view'], view)
      .set('invalidateCache', invalidateCache);
    doSubmit = Form.createSubmitFn(id, s);
  }

  return bindActionCreators(
    {
      doSubmit,
      showErrors: (value) => form.showErrors(view, id, value),
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccordionForm);
