import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent } from '@material-ui/core';
import { Submit } from '../form';
import Dialog from '../Dialog';
import SubmitButton from '../form/SubmitButton';
import Form from '../Form';

const BUTTON_STYLE = { minWidth: '140px', padding: '4px 12px' };

function renderActionButtons(buttonsProps, communicating) {
  if (!buttonsProps || buttonsProps.length === 0) {
    return null;
  }

  return buttonsProps.map((props) => {
    const { color, disabled, text, onClick } = props;
    return (
      <SubmitButton
        key={text}
        color={color}
        disabled={communicating || disabled}
        style={BUTTON_STYLE}
        onClick={onClick}
        align="right"
      >
        <b>{text}</b>
      </SubmitButton>
    );
  });
}

function DialogForm(props) {
  const {
    id,
    view,
    open,
    title,
    submit,
    onClose,
    onEnter,
    onMount,
    onSubmit,
    doSubmit,
    children,
    hasError,
    validator,
    clearForm,
    submitText,
    showErrors,
    isSubmitting,
    disableSubmit,
    communicating,
    actionButtons,
    notClearOnClose,
    ...others
  } = props;
  const [firstOpen, setFirstOpen] = React.useState(false);

  return (
    <Form id={id} validator={validator} view={view}>
      <Dialog
        id={`${id}:dialog`}
        title={title}
        open={open}
        onClose={() => {
          onClose();
          if (!notClearOnClose) {
            clearForm();
          }
        }}
        onEnter={() => {
          onEnter();
          if (!firstOpen) {
            onMount();
            setFirstOpen(true);
          }
        }}
        {...others}
      >
        <DialogContent>{children}</DialogContent>
        <DialogActions style={{ margin: '8px 16px 24px' }}>
          {renderActionButtons(actionButtons, communicating)}
          <SubmitButton
            color="primary"
            align="right"
            style={BUTTON_STYLE}
            disabled={communicating || disableSubmit}
            loading={isSubmitting}
            onClick={() => {
              showErrors(hasError);
              if (!hasError) {
                if (submit) {
                  doSubmit();
                } else {
                  onSubmit();
                }
              }
            }}
          >
            <b>{submit ? submit.buttonText : submitText}</b>
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </Form>
  );
}

DialogForm.defaultProps = {
  id: 'dialogForm',
  validator: null,
  submit: null,
  title: null,
  actionButtons: [],
  onEnter: () => null,
  onMount: () => null,
  doSubmit: () => null,
  onSubmit: () => null,
  submitText: 'Salvar',
  disableSubmit: false,
  notClearOnClose: false,
};

DialogForm.propTypes = {
  /*
   * id do `Form`.
   */
  id: PropTypes.string,

  /*
   * A prop view é passada para o formulário.
   */
  view: PropTypes.string.isRequired,

  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /*
   * A prop validator é passada para o formulário.
   */
  validator: PropTypes.func,

  /*
   * Se o dialog deve ser aberto.
   */
  open: PropTypes.bool.isRequired,

  /*
   * Função chamada ao tentar fechar o Dialog.
   */
  onClose: PropTypes.func.isRequired,

  /*
   * Flag que sinaliza se ao fechar a janela o formulário deve
   * ser limpo.
   */
  notClearOnClose: PropTypes.bool,

  /*
   * Função chamada ao abrir o Dialog.
   */
  onEnter: PropTypes.func,

  /*
   * Função chamada apenas na primeira vez que a dialog é aberta.
   */
  onMount: PropTypes.func,

  /*
   * Função chamada ao submeter um formulário.
   */
  onSubmit: PropTypes.func,

  /*
   * O texto do botão pode ser definido por aqui ou pelo `buttonText`
   * no submit. No caso, o valor do submit sobreescreve este valor.
   */
  submitText: PropTypes.string,

  /*
   * Se o dialog deve ser aberto.
   */
  disableSubmit: PropTypes.bool,

  /*
   * Se o dialog deve ser aberto.
   */
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['primary', 'secondary', 'light']),
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      text: PropTypes.string,
    }),
  ),

  /*
   * Objeto Submit que será chamado ao submete o formulário.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  submit: PropTypes.instanceOf(Submit),

  /*
   * Função que executa o submit.
   */
  doSubmit: PropTypes.func,
};

export default DialogForm;
