// @TODO Update: componente deve virar uma pasta
import React from 'react';
import { Set } from 'immutable';
// @TODO Update: Material UI migration hotspot - vai virar hook
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Zoom,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@material-ui/core';

// @TODO Update: Material UI migration hotspot - vai virar hook
function styles(theme) {
  const { pxToRem } = theme.typography;
  const { secondary } = theme.palette.text;
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);

  return {
    padding: {
      paddingTop: 2,
    },
    icon: {
      flexBasis: '4%',
      fontSize: pxToRem(13),
    },
    title: {
      flexBasis: '45%',
    },
    titleWithIcon: {
      flexBasis: '41%',
    },
    heading: {
      flexShrink: 0,
      fontWeight: 'bold',
      fontSize: pxToRem(15),
    },
    secondaryHeading: {
      color: secondary,
      fontSize: pxToRem(15),
      wordBreak: 'break-all',
    },
    zoom: {
      margin: 'auto',
      marginLeft: u,
    },
    expansionPanelDetails: {
      paddingLeft: 7 * u,
    },
  };
}

// @TODO Update: remover componente para um arquivo próprio
function CustomExpansionPanel(props) {
  const {
    expanded,
    onChange,
    disabled,
    notExclusive,
    freezePadding,
    children,
    style,
    ...others
  } = props;
  // @TODO Update: sombras devem ser passadas no tema (theme)
  const customStyle = {
    boxShadow:
      ' 0px 0.5px 5px 0px rgba(0,0,0,0.2),' +
      ' 0px 0.5px 2px 0px rgba(0,0,0,0.14),' +
      ' 0px 1px 1px -2px rgba(0,0,0,0.12)',
    ...(freezePadding ? { margin: '0px 0px 16px' } : {}),
    ...style,
  };

  return (
    <Accordion
      expanded={notExclusive ? undefined : expanded}
      disabled={disabled}
      onChange={onChange}
      style={customStyle}
      {...others}
    >
      {children}
    </Accordion>
  );
}

function Expandable(props) {
  const {
    width,
    style,
    panels,
    classes,
    disabled,
    onChange,
    iconSize,
    titleStyle,
    notExclusive,
    freezePadding,
    descriptionStyle,
    notHideDescription,
  } = props;
  const defaultPanel = panels.map((panel) => {
    const { title, isDefaultExpanded } = panel;
    return isDefaultExpanded ? title : null;
  });
  const [currentPanel, setCurrentPanel] = React.useState(
    defaultPanel ? Set(defaultPanel) : Set(),
  );
  const isMobile = ['xs', 'sm'].includes(width);

  return (
    <>
      {panels.map((panel) => {
        const {
          id,
          icon,
          title,
          render,
          action,
          headline,
          description,
        } = panel;
        let actionEl = null;
        const uniqueId = title || id;

        if (action) {
          actionEl = typeof action === 'function' ? action() : action;
        }
        const isExpanded = currentPanel.includes(uniqueId);

        return (
          <CustomExpansionPanel
            key={uniqueId}
            style={style}
            notExclusive={notExclusive}
            expanded={isExpanded}
            disabled={disabled}
            freezePadding={freezePadding}
            onChange={(event, expanded) => {
              if (expanded) {
                if (!notExclusive) {
                  setCurrentPanel(Set([uniqueId]));
                } else {
                  setCurrentPanel(currentPanel.add(uniqueId));
                }
              } else if (currentPanel.includes(uniqueId)) {
                setCurrentPanel(currentPanel.remove(uniqueId));
              }
              const label = expanded ? title : null;
              onChange(event, expanded, label);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize={iconSize} />}
            >
              {typeof headline === 'function'
                ? headline({ isExpanded })
                : headline}
              {icon && !headline && (
                <Typography
                  className={classNames(classes.heading, classes.icon)}
                >
                  {icon}
                </Typography>
              )}
              {!headline && (
                <Typography
                  className={classNames(
                    classes.title,
                    classes.heading,
                    icon && classes.padding,
                    icon ? classes.titleWithIcon : classes.title,
                  )}
                  style={titleStyle}
                  color="primary"
                >
                  {title}
                </Typography>
              )}
              {!headline && description && !isMobile && (
                <Zoom
                  in={notHideDescription || !isExpanded}
                  className={classes.zoom}
                >
                  <Typography
                    className={classes.secondaryHeading}
                    style={descriptionStyle}
                  >
                    {description}
                  </Typography>
                </Zoom>
              )}
            </AccordionSummary>
            <AccordionDetails
              className={icon ? classes.expansionPanelDetails : ''}
            >
              {render()}
            </AccordionDetails>
            {action && (
              <AccordionActions style={{ marginRight: '48px' }}>
                {actionEl}
              </AccordionActions>
            )}
          </CustomExpansionPanel>
        );
      })}
    </>
  );
}

Expandable.defaultProps = {
  descriptionStyle: null,
  onChange: () => null,
  notExclusive: false,
  notHideDescription: false,
  iconSize: 'default',
  freezePadding: false,
  titleStyle: null,
  disabled: false,
  style: null,
};

Expandable.propTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        render: PropTypes.func,
        icon: PropTypes.element,
        description: PropTypes.string,
        isDefaultExpanded: PropTypes.bool,
        title: PropTypes.string.isRequired,
        action: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
      }),
      PropTypes.shape({
        render: PropTypes.func,
        id: PropTypes.string.isRequired,
        action: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
        headline: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
      }),
    ]),
  ).isRequired,
  notExclusive: PropTypes.bool,
  notHideDescription: PropTypes.bool,
  freezePadding: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  descriptionStyle: PropTypes.object,
  iconSize: PropTypes.oneOf(['default', 'inherit', 'large', 'small']),
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(withWidth()(Expandable));
