// @TODO Update: remover componente para uma pasta própria
import React, { useState } from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  Tooltip,
  Typography,
  IconButton,
  withStyles,
  // @TODO Update: vai virar hook useMatchWidth (criar hook)
  withWidth,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import { titleize } from 'underscore.string';
import { Set, List } from 'immutable';
import PropTypes from 'prop-types';
import { some } from '../../core/util';
import ToggleBadge from './ToggleBadge';
import { colors } from '../../theme';

function styles(theme) {
  const u = theme.spacing(1);

  return {
    chip: {
      margin: u,
      fontSize: '14px',
      '& span': {
        marginRight: 'auto',
      },
    },
    rightFieldSet: {
      width: '100%',
      display: 'block',
    },
    centerFieldSet: {
      width: '100%',
    },
    selected: {
      '&&': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    selectDisabled: {
      '&&': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    lightSelected: {
      '&&': {
        fontWeight: 'bold',
        backgroundColor: colors.blueLight2,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: colors.blueLight2,
        },
      },
    },
    lightSelectDisabled: {
      '&&': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: colors.blueLight2,
        },
      },
    },
    toggle: {
      color: theme.palette.text.primary,
      textTransform: 'none',
      fontSize: '14px',
      height: '24px',
      padding: '0px 12px',
    },
    lightToggle: {
      color: colors.blueLight2,
      textTransform: 'none',
      fontSize: '12px',
      height: '24px',
      padding: '0px 12px 2px',
    },
    info: {
      margin: '0 2px -5px 8px',
    },
    infoIcon: {
      width: '22px',
      height: '22px',
    },
    infoButton: {
      color: 'currentColor',
    },
    infoClickable: {
      '&:hover': {
        color: theme.palette.primary.light,
      },
      transition: 'color 200ms linear',
    },
    disabled: {
      color: 'gray',
    },
    toggleGroup: {
      padding: 0,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      display: 'inline-table',
      width: 'max-content',
    },
  };
}

/**
 * Retorna o novo estado do Checkbox.
 */
function newState(state, value) {
  const s = state || Set();
  if (s.has(value)) {
    return s.remove(value);
  }
  return s.add(value);
}

/**
 * Componente que representa cada opção de um radio button.
 */
// @TODO Update: remover componente para um arquivo próprio
function Label(props) {
  const { onHelperClick, option, activeHelper, setActiveHelper } = props;
  const label = option.get('label');
  const value = option.get('value');
  if (!onHelperClick) {
    return label;
  }

  return (
    <span>
      {label}
      <ToggleBadge
        active={activeHelper === value}
        onClick={(e) => {
          if (activeHelper === value) {
            onHelperClick(e, null);
            setActiveHelper(null);
          } else {
            setActiveHelper(value);
          }
        }}
      >
        ?
      </ToggleBadge>
    </span>
  );
}

function handleChange(event, props) {
  const { value, onChange, onBlur } = props;
  const isPrimitive = typeof event === 'string' || typeof event === 'number';
  const newContent = isPrimitive ? event : event.target.value;
  const newValue = !Set.isSet(value) ? '' : newState(value, newContent);
  if (onChange) {
    onChange(event, newValue);
  }
  if (onBlur) {
    onBlur(event);
  }
}

// @TODO Update: remover componente para um arquivo próprio
function CheckboxOptions(props) {
  const { options, disabled, value, onBlur, onChange } = props;
  return (
    <FormGroup>
      {options &&
        options.map((opt) => (
          <FormControlLabel
            key={opt.get('value')}
            label={opt.get('label')}
            value={opt.get('value') || ''}
            disabled={disabled}
            control={
              <Checkbox
                onChange={(e) => handleChange(e, { onBlur, onChange, value })}
                checked={Boolean(value && value.has(opt.get('value')))}
                style={{ paddingTop: 8, paddingBottom: 8 }}
                color="primary"
              />
            }
          />
        ))}
    </FormGroup>
  );
}

// @TODO Update: remover componente para um arquivo próprio
function RadioOptions(props) {
  const {
    setActiveHelper,
    onHelperClick,
    defaultValue,
    activeHelper,
    disabled,
    onChange,
    options,
    rowLeft,
    onBlur,
    value,
    label,
    width,
    color,
    row,
  } = props;

  return (
    <>
      {/* @TODO Update: vai virar hook useMatchWidth (criar hook) */}
      {rowLeft && width !== 'xs' && (
        <Typography style={{ float: 'left', margin: '15px 15px 0 0' }}>
          {label}
        </Typography>
      )}
      <MuiRadioGroup
        value={String(value)}
        aria-label={label || ''}
        // @TODO Update: vai virar hook useMatchWidth (criar hook)
        row={Boolean(row) && width !== 'xs'}
        onChange={(e) => handleChange(e, { onBlur, onChange, value })}
      >
        {options &&
          options.map((opt) => (
            <FormControlLabel
              style={{ float: 'left' }}
              key={`${opt.get('label')}-${opt.get('value')}`}
              value={String(opt.get('value')) || String(defaultValue) || ''}
              label={
                <Label
                  option={opt}
                  onHelperClick={onHelperClick}
                  activeHelper={activeHelper}
                  setActiveHelper={setActiveHelper}
                />
              }
              disabled={disabled}
              control={<Radio color={color} />}
            />
          ))}
      </MuiRadioGroup>
    </>
  );
}

// @TODO Update: remover componente para um arquivo próprio
function ChipOptions(props) {
  const { options, value, onChange, onBlur, minWidth, classes } = props;
  return (
    <div style={{ marginTop: '8px' }}>
      {options &&
        options.map((opt) => {
          const isSelected = value.has(opt.get('value'));
          return (
            <Chip
              key={`${opt.get('label')}-${opt.get('value')}`}
              label={titleize(opt.get('label'))}
              color="primary"
              variant={isSelected ? 'default' : 'outlined'}
              clickable
              className={classes.chip}
              style={{ minWidth }}
              onClick={() => {
                handleChange(opt.get('value'), { onBlur, onChange, value });
              }}
              onDelete={
                isSelected
                  ? () => {
                      if (isSelected) {
                        handleChange(opt.get('value'), {
                          onBlur,
                          onChange,
                          value,
                        });
                      }
                    }
                  : undefined
              }
            />
          );
        })}
    </div>
  );
}

// @TODO Update: remover componente para um arquivo próprio
function Toggle(props) {
  const {
    options,
    align,
    value,
    disabled,
    defaultValue,
    onChange,
    onBlur,
    minWidth,
    classes,
    lightToggle,
  } = props;

  return (
    <ToggleButtonGroup
      style={
        align && align === 'center'
          ? { margin: 'auto' }
          : { margin: 0, float: 'right' }
      }
      value={value || defaultValue}
      size="small"
      exclusive
      onChange={(e, v) => {
        if (!some(v)) {
          return;
        }
        handleChange(v, { onBlur, onChange, value });
      }}
      classes={{ root: classes.toggleGroup }}
    >
      {options.map((opt) => (
        <ToggleButton
          classes={
            !lightToggle
              ? {
                  root: classes.toggle,
                  selected: !disabled
                    ? classes.selected
                    : classes.selectDisabled,
                }
              : {
                  root: classes.lightToggle,
                  selected: !disabled
                    ? classes.lightSelected
                    : classes.lightSelectDisabled,
                }
          }
          key={`${opt.get('label')}-${opt.get('value')}`}
          value={opt.get('value')}
          style={{ minWidth }}
          disabled={disabled}
        >
          {opt.get('label')}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

function renderIconInfo(props) {
  const {
    label,
    value,
    status,
    message,
    width,
    onClickMessage,
    classes,
  } = props;

  return (
    <>
      <div style={{ width: width === 'xs' && '300px' }}>
        {label}
        {(value || status === 'on') && (
          <Tooltip title={message}>
            <span className={classes.info}>
              <IconButton
                style={{ padding: 0 }}
                disableRipple
                disabled={!onClickMessage}
                onClick={onClickMessage}
                className={classNames(
                  classes.infoButton,
                  Boolean(onClickMessage) && classes.infoClickable,
                )}
              >
                <InfoIcon className={classes.infoIcon} />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
    </>
  );
}

function ControlGroup(props) {
  const {
    row,
    type,
    width,
    label,
    error,
    value,
    color,
    align,
    onBlur,
    options,
    rowLeft,
    classes,
    disabled,
    message,
    required,
    onChange,
    minWidth,
    exclusive,
    helperText,
    defaultValue,
    onHelperClick,
    lightToggle,
  } = props;

  const [activeHelper, setActiveHelper] = useState(null);

  const labelElement = !label ? null : (
    <FormLabel
      component="legend"
      error={error}
      disabled={disabled}
      required={required}
      style={
        type === 'toggle'
          ? {
              transform: 'translate(0, 1.5px) scale(0.75)',
              transformOrigin: 'top left',
              marginBottom: '8px',
            }
          : {
              // @TODO Update: vai virar hook useMatchWidth (criar hook)
              width: width === 'xs' && '100%',
            }
      }
    >
      {message ? renderIconInfo(props) : label}
    </FormLabel>
  );

  const helperTextElement = !helperText ? null : (
    <FormHelperText error={error}>{helperText}</FormHelperText>
  );

  function styleFieldSet() {
    if (rowLeft) {
      // @TODO Update: vai virar hook useMatchWidth (criar hook)
      return width !== 'xs' ? { position: 'relative', display: 'inline' } : {};
    }
    if (align) {
      return align === 'right'
        ? { marginTop: '15px', width: '100%', display: 'block' }
        : { marginTop: '15px', width: '100%', padding: '10px 0' };
    }
    return { marginTop: '15px' };
  }

  return (
    <FormControl component="fieldset" style={styleFieldSet()}>
      {(!rowLeft || width === 'xs') && labelElement}

      {type === 'checkbox' && (
        <CheckboxOptions
          options={options}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
      {type === 'radio' && (
        <RadioOptions
          options={options}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onChange={onChange}
          label={label}
          row={row}
          width={width}
          onHelperClick={onHelperClick}
          setActiveHelper={setActiveHelper}
          activeHelper={activeHelper}
          color={color}
          rowLeft={rowLeft}
        />
      )}
      {type === 'chipcheck' && (
        <ChipOptions
          options={options}
          value={value}
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          minWidth={minWidth}
          classes={classes}
        />
      )}
      {type === 'toggle' && (
        <Toggle
          align={align}
          options={options}
          value={value}
          label={label}
          disabled={disabled}
          onChange={onChange}
          exclusive={exclusive}
          width
          onBlur={onBlur}
          lightToggle={lightToggle}
          classes={classes}
          minWidth={minWidth}
        />
      )}
      {helperTextElement}
    </FormControl>
  );
}

ControlGroup.defaultProps = {
  value: Set(),
  label: null,
  onChange: null,
  disabled: false,
  minWidth: '150px',
  error: false,
  row: true,
  color: 'primary',
  helperText: null,
  defaultValue: '',
  options: null,
  onHelperClick: null,
  width: null,
  lightToggle: false,
};

ControlGroup.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio', 'chipcheck', 'toggle'])
    .isRequired,
  /**
   * Veja `Material UI TextField`.
   */
  label: PropTypes.string,
  /**
   * Veja `Material UI TextField`.
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Set),
  ]),
  /**
   * Largura minima do Chip para o tipo chipcheck
   */
  minWidth: PropTypes.string,
  /**
   * Veja `Material UI TextField`.
   */
  onChange: PropTypes.func,

  /**
   * Veja `Material UI TextField`.
   */
  disabled: PropTypes.bool,

  /**
   * Veja `Material UI TextField`.
   */
  error: PropTypes.bool,
  /**
   * Se `true` as opções serão dispostas em linha
   */
  row: PropTypes.bool,
  /**
   * A cor dos botões.
   */
  color: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * Veja `Material UI TextField`.
   */
  helperText: PropTypes.string,
  /**
   * Veja `Material UI TextField`.
   */
  defaultValue: PropTypes.string,
  /**
   * Uma lista de no formato `Map({ value, label })`.
   */
  options: PropTypes.instanceOf(List),
  /**
   * Handler para os botões de ajuda.
   */
  onHelperClick: PropTypes.func,
  width: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  lightToggle: PropTypes.bool,
};

// @TODO Update: vai virar hook useMatchWidth (criar hook)
export default withWidth()(withStyles(styles)(ControlGroup));
