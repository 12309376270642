import React from 'react';
import PropTypes from 'prop-types';
import MuiCancelIcon from '@material-ui/icons/Cancel';
import { IconButton, Tooltip } from '@material-ui/core';
import MuiCheckIcon from '@material-ui/icons/CheckCircle';
import withStyles from '@material-ui/core/styles/withStyles';

function styles(theme) {
  return {
    inactive: {
      padding: 4,
      color: 'rgba(0, 0, 0, 0.54)',
      transition: 'color 200ms ease',
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
  };
}

function ActivationToggleButton(props) {
  const { classes, active, disabled, onClick } = props;

  if (active) {
    return <MuiCheckIcon color="primary" style={{ margin: 4 }} />;
  }
  if (disabled) {
    return (
      <MuiCancelIcon style={{ margin: 4, color: 'rgba(0, 0, 0, 0.54)' }} />
    );
  }
  return (
    <Tooltip title="Ativar">
      <span>
        <IconButton className={classes.inactive} onClick={onClick}>
          <MuiCheckIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}

ActivationToggleButton.defaultProps = {
  disabled: false,
  active: false,
};

ActivationToggleButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
};

export default withStyles(styles)(ActivationToggleButton);
