import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BugReport, MoreVert } from '@material-ui/icons';
import IconButton from './IconButton';

const emptyTuple = Map();

/**
 * Retorna as propriedade do icone que será renderizado.
 */
function packActions(actions, setAnchor, pack, packIcon) {
  if (!actions || actions.length === 0) {
    return null;
  }

  // Havendo uma action apenas, um unico botão é renderizado.
  if (actions.length === 1 && pack !== 'always') {
    return actions[0];
  }

  return {
    // tooltip: 'Ações',
    icon: packIcon || <MoreVert />,
    disabled: false,
    onClick: (e, tuple) => {
      setAnchor(e.currentTarget, tuple);
    },
    packedActions: actions,
  };
}

function ActionButton(props) {
  const { actions, tuple, setAnchor, pack, packIcon, style } = props;
  const btnProps = packActions(actions, setAnchor, pack, packIcon);
  if (!btnProps) {
    return null;
  }

  const {
    label,
    tooltip,
    disabled = false,
    onClick = () => null,
    icon = <BugReport />,
    // as props abaixo só são listadas para não aparecerem
    // na lista de props depreciadas
    link,
    href,
    packedActions,
    ...other
  } = btnProps;
  const isFn = typeof disabled === 'function';
  const destinyLink = typeof link === 'function' ? link(tuple) : link;
  const isDisabled = isFn ? disabled(tuple || emptyTuple) : disabled;
  const linkProps = destinyLink ? { component: Link, to: destinyLink } : {};

  return (
    <div style={style}>
      <IconButton
        size="small"
        variant="contained"
        disabled={isDisabled}
        key={tooltip || label}
        tooltip={actions && actions.length > 1 ? label : null}
        onClick={(e) => onClick(e, tuple || emptyTuple)}
        {...linkProps}
        {...other}
      >
        {icon}
      </IconButton>
    </div>
  );
}

ActionButton.defaultProps = {
  pack: 'default',
  actions: null,
  tuple: null,
  style: null,
  packIcon: null,
};

ActionButton.propTypes = {
  tuple: PropTypes.instanceOf(Map),
  setAnchor: PropTypes.func.isRequired,
  pack: PropTypes.oneOf(['default', 'always']),
  packIcon: PropTypes.element,
  actions: PropTypes.arrayOf(PropTypes.shape(IconButton.propTypes)),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default ActionButton;
