import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  // @TODO Update: vai virar hook useMatchWidth (criar hook)
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import { Feedback, Warning } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from './Tag';
// @TODO Update: como se livrar dessas referências diretas?
import { colors } from '../theme';

const typeIcon = {
  info: Feedback,
  warning: Warning,
  success: Warning,
};

// @TODO Update: Material UI migration hotspot - vai virar hook
function styles(theme) {
  return {
    icon: {
      fontSize: 96,
    },
    title: {
      margin: '8px',
    },
    message: {
      margin: '12px',
    },
    children: {
      margin: '0 20x 20px 20px',
      alignItems: 'center',
    },
    buttonWrapper: {
      textAlign: 'center',
    },
    button: {
      fontWeight: 'bold',
    },
    warningColor: {
      color: colors.warning,
    },
    infoColor: {
      color: theme.palette.primary.main,
    },
    successColor: {
      color: colors.success,
    },
  };
}

function ConfirmationDialog(props) {
  const {
    id,
    no,
    yes,
    type,
    open,
    onNo,
    onYes,
    title,
    width,
    classes,
    message,
    onClose,
    tagText,
    tagTitle,
    maxWidth,
    children,
    communicating,
    disableCloseOnNo,
    disableCloseOnYes,
  } = props;

  // @TODO Update: vai virar hook useMatchWidth (criar hook)
  const isSmallScreen = width === 'xs' || width === 'sm';
  const Icon = typeIcon[type];
  const onClickYes = (event) => {
    onYes(event);
    if (!disableCloseOnYes) {
      onClose(event);
    }
  };
  const onClickNo = (event) => {
    onNo(event);
    if (!disableCloseOnNo) {
      onClose(event);
    }
  };

  return (
    <Dialog
      id={id}
      open={open}
      scroll="body"
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={isSmallScreen}
    >
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          <Icon className={classNames(classes.icon, classes[`${type}Color`])} />
        </div>
        {title && (
          <Typography component="center" variant="h6" className={classes.title}>
            {title}
          </Typography>
        )}
        {tagText && <Tag title={tagTitle} text={tagText} />}
        <Typography
          component="center"
          variant="subtitle1"
          className={classes.message}
        >
          {message}
        </Typography>
        <div className={classes.children}>{children}</div>
        {children && <div style={{ padding: '8px' }} />}
        <div className={classes.buttonWrapper}>
          <Button
            size="large"
            color="primary"
            onClick={onClickYes}
            disabled={communicating}
            className={classes.button}
          >
            {yes}
          </Button>
          <Button
            size="large"
            color="primary"
            onClick={onClickNo}
            disabled={communicating}
            className={classes.button}
          >
            {no}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ConfirmationDialog.defaultProps = {
  no: 'Não',
  yes: 'Sim',
  open: false,
  title: null,
  tagText: '',
  tagTitle: '',
  maxWidth: 'xs',
  children: null,
  type: 'warning',
  onNo: () => null,
  onYes: () => null,
  communicating: false,
  disableCloseOnNo: false,
  disableCloseOnYes: false,
};

ConfirmationDialog.propTypes = {
  /**
   * ID no `Dialog` que será passada para o `DOM`.
   */
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.element,
  message: PropTypes.string.isRequired,
  yes: PropTypes.string,
  no: PropTypes.string,
  tagTitle: PropTypes.string,
  tagText: PropTypes.string,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  communicating: PropTypes.bool,
  disableCloseOnYes: PropTypes.bool,
  disableCloseOnNo: PropTypes.bool,
  type: PropTypes.oneOf(['warning', 'info', 'success']),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md']),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const StyledConfirmationDialog = withStyles(styles)(ConfirmationDialog);

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withWidth()(StyledConfirmationDialog);
