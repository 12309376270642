import React from 'react';
import moment from 'moment';
import { List } from 'immutable';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import datefilter from '../../core/components/datefilter/actions';
import qdatefilter from '../../core/components/datefilter/queries';
import storage from '../../core/storage/actions';
import { getRange, getLabel } from './props';
import FilterPopover from './FilterPopover';
import { Choices } from './consts';
import { Filter } from '../../core/comm';
import qstorage from '../../core/storage/queries';

function styles(theme) {
  const u = theme.spacing(1);
  const padding = 2 * u;
  const { palette } = theme;
  const { white } = palette.common;
  const { main: primary } = palette.primary;

  return {
    anchor: {
      padding: 0,
      margin: 0,
    },
    root: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      borderRadius: 4 * u,
      background: primary,
    },
    rootPadding: {
      paddingLeft: padding,
      paddingRight: padding,
    },
    container: {
      width: '100%',
    },
    popover: {
      maxWidth: '650px',
    },
    arrowButton: {
      width: 4 * u,
      minWidth: 2.5 * u,
    },
    arrowButtonRoot: {
      borderRadius: '50%',
    },
    button: {
      width: '100%',
      overflow: 'hidden',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    dateText: {
      minWidth: 170,
    },
    buttonTextPrimary: {
      color: white,
    },
    formBody: {
      padding: 2 * u,
      width: '100%',
    },
    formButtons: {
      textAlign: 'center',
      '& > button': {
        margin: 2 * u,
        marginTop: 3 * u,
      },
    },
  };
}

// ---------------------------------
//
// Componentes
//
// ---------------------------------

function fromDateToFilter(startDate, endDate, dateField, tab) {
  const type = tab === Choices.MONTHLY ? 'months' : 'days';
  const isSeparatedFields = typeof dateField === 'object';
  return List([
    // Data de início
    Filter({
      field: isSeparatedFields ? dateField.startField : dateField,
      op: '>=',
      value: moment(startDate).startOf(type).format(),
    }),
    // Data Fim
    Filter({
      field: isSeparatedFields ? dateField.endField : dateField,
      op: '<=',
      value: moment(endDate).endOf(type).format(),
    }),
  ]);
}

function quickChange(params) {
  const {
    tab,
    date,
    timer,
    setDate,
    setTimer,
    onFilter,
    variation,
    dateField,
    extraFilters,
    setDateFilter,
  } = params;

  return () => {
    if (timer) {
      clearTimeout(timer);
    }

    const fmt = tab === Choices.DAILY ? 'DD/MM/YYYY' : 'MMMM [de] YYYY';
    const type = tab === Choices.DAILY ? 'days' : 'months';
    const newDate = moment(date, fmt).add(variation, type);
    setDate(newDate);

    const callFilter = () => {
      const filters = fromDateToFilter(newDate, newDate, dateField, tab);
      setDateFilter(filters);

      const allFilters =
        extraFilters.size > 0 ? filters.concat(extraFilters) : filters;

      onFilter(allFilters, {
        choice: tab,
        endDate: newDate.startOf(type).format(),
        date: newDate.endOf(type).format(),
      });
    };

    setTimer(setTimeout(callFilter, 300));
  };
}

function useComponentDidMount(props) {
  const {
    tab,
    date,
    endDate,
    onFilter,
    dateField,
    setDateFilter,
    lastOnesOption,
  } = props;
  React.useEffect(() => {
    const [newDate, newEndDate] = getRange(tab, date, endDate, lastOnesOption);
    const filters = fromDateToFilter(newDate, newEndDate, dateField, tab);
    setDateFilter(filters);
    onFilter(filters, {
      choice: tab,
      date: newDate,
      endDate: newEndDate,
    });
  }, []);
}

function DateFilter(props) {
  const {
    id,
    tab,
    date,
    setTab,
    endDate,
    setDate,
    onFilter,
    classes,
    className,
    dateField,
    extraFilters,
    defaultRange,
    setDateFilter,
    lastOnesOption,
    setLastOnesOption,
  } = props;

  const refEl = React.useRef(null);
  const [timer, setTimer] = React.useState();
  const [open, setOpen] = React.useState(false);
  const label = getLabel(tab, date, endDate, lastOnesOption);
  const hasArrowsBtn = tab !== Choices.PERIODICALLY && tab !== Choices.LAST;
  const quickChangeParams = {
    setDateFilter,
    extraFilters,
    variation: 1,
    dateField,
    setTimer,
    onFilter,
    setDate,
    endDate,
    timer,
    date,
    tab,
  };

  const commitState = (params) => () => {
    const { tab, date, endDate, option = defaultRange } = params;
    if (!date) {
      return;
    }
    setTab(tab);
    setDate(date, endDate);
    setLastOnesOption(option);
    const filters = fromDateToFilter(date, endDate, dateField, tab);
    setDateFilter(filters);
    const allFilters =
      extraFilters.size > 0 ? filters.concat(extraFilters) : filters;

    onFilter(allFilters, {
      choice: tab,
      endDate,
      date,
    });
  };

  useComponentDidMount(props);
  return (
    <div ref={refEl} className={classes.anchor}>
      <div
        className={classNames(
          className,
          classes.root,
          !hasArrowsBtn && classes.rootPadding,
        )}
      >
        {hasArrowsBtn && (
          <Button
            style={{ float: 'left' }}
            className={classes.arrowButton}
            classes={{ root: classes.arrowButtonRoot }}
            onClick={quickChange({ ...quickChangeParams, variation: -1 })}
          >
            <ChevronLeft className={classes.buttonTextPrimary} />
          </Button>
        )}
        <Button
          color="primary"
          className={classes.button}
          onClick={() => setOpen(!open)}
          classes={{
            textPrimary: classNames(
              classes.buttonTextPrimary,
              classes.dateText,
            ),
          }}
        >
          {label}
        </Button>
        {hasArrowsBtn && (
          <Button
            style={{ float: 'right' }}
            className={classes.arrowButton}
            onClick={quickChange(quickChangeParams)}
            classes={{ root: classes.arrowButtonRoot }}
          >
            <ChevronRight className={classes.buttonTextPrimary} />
          </Button>
        )}
      </div>
      {open && (
        <FilterPopover
          id={id}
          tab={tab}
          open={open}
          date={date}
          endDate={endDate}
          classes={classes}
          anchor={refEl.current}
          commitState={commitState}
          onClose={() => setOpen(false)}
          lastOnesOption={lastOnesOption}
        />
      )}
    </div>
  );
}

// ---------------------------------
//
// Mapeamento
//
// ---------------------------------

function mapStateToProps(state, ownProps) {
  const {
    view,
    defaultTab,
    table = 'table',
    id = 'datefilter',
    defaultRange = '7days',
  } = ownProps;
  const currentState = qdatefilter.getState(state, view, id);

  return {
    date: currentState.get('date'),
    endDate: currentState.get('endDate'),
    tab: currentState.get('tab') || defaultTab,
    lastOnesOption: currentState.get('lastOnesOption') || defaultRange,
    extraFilters: qstorage.get(
      state,
      view,
      [table, 'filterForm', 'submit', '!@filters'],
      List(),
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { id = 'datefilter', view, table = 'table' } = ownProps;

  const actions = {
    setTab: (tab) => datefilter.setTab(view, id, tab),
    setDate: (date, endDate) => datefilter.setDate(view, id, date, endDate),
    setDateFilter: (dateFilter) =>
      storage.set(view, [table, 'datefilter'], dateFilter),
    setLastOnesOption: (option) =>
      datefilter.setLastOnesOption(view, id, option),
  };

  return bindActionCreators(actions, dispatch);
}

DateFilter.defaultProps = {
  table: 'table',
  id: 'datefilter',
  className: null,
  dateField: 'data',
  defaultRange: '7days',
  defaultTab: Choices.DAILY,
};

DateFilter.propTypes = {
  id: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  dateField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      startField: PropTypes.string.isRequired,
      endField: PropTypes.string.isRequired,
    }),
  ]),
  defaultRange: PropTypes.oneOf(['7days', '15days', '30days']),
  // eslint-disable-next-line react/no-unused-prop-types
  defaultTab: PropTypes.oneOf(['daily', 'monthly', 'periodically', 'last']),
  // eslint-disable-next-line react/no-unused-prop-types
  table: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  view: PropTypes.string.isRequired,
};

DateFilter.Choices = Choices;

export default React.memo(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DateFilter)),
);
