import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  Button,
  Collapse,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Submit } from '../form';
import Dialog from '../Dialog';
import SubmitButton from '../form/SubmitButton';
import Form from '../Form';
import DialogStep from './DialogStep';
import Carousel from './Carousel';

function styles() {
  return {
    dialogContent: {
      overflowX: 'hidden',
      height: 'auto',
      transition: 'height 200ms',
    },
    button: {
      minWidth: '140px',
      padding: '4px 12px',
      fontWeight: 'bold',
    },
  };
}

function renderTextButton(submit, submitText, isLast) {
  if (!isLast) {
    return 'Avançar';
  }
  return submit ? submit.buttonText : submitText;
}

function useless() {
  return null;
}

function DialogStepForm(props) {
  const {
    id,
    view,
    open,
    title,
    steps,
    submit,
    header,
    actions,
    onClose,
    classes,
    onEnter,
    onMount,
    onSubmit,
    doSubmit,
    hasError,
    validator,
    clearForm,
    submitText,
    showErrors,
    hideSubmit,
    onChangeStep,
    isSubmitting,
    disableSubmit,
    communicating,
    dialogActions,
    hideBackButton,
    notClearOnClose,
    ...others
  } = props;
  const [firstOpen, setFirstOpen] = React.useState(false);
  const [currentState, setCurrentState] = React.useState({
    act: 'next',
    index: 0,
  });

  const { index: currentIndex, act } = currentState;
  const isLastStep = currentIndex === steps.length - 1;
  const { title: stepTitle, onShow, validator: stepValidator } =
    steps[currentIndex] || {};

  const moveNext = () =>
    setCurrentState({
      act: 'next',
      index: currentIndex + 1,
    });
  const movePrev = () =>
    setCurrentState({
      act: 'prev',
      index: currentIndex - 1,
    });
  const moveReset = () =>
    setCurrentState({
      act: 'next',
      index: 0,
    });

  const actionParams = {
    moveNext,
    movePrev,
    moveReset,
    currentIndex,
    currentStep: steps[currentIndex] || {},
  };

  React.useEffect(() => {
    if (onShow) {
      onShow();
    }
    onChangeStep(currentIndex);
  }, [currentIndex]);

  React.useEffect(() => {
    moveReset();
  }, [steps.length]);

  return (
    <Form id={id} validator={stepValidator || useless} view={view}>
      <Dialog
        id={`${id}:dialog`}
        title={title || stepTitle}
        open={open}
        actions={dialogActions}
        onClose={() => {
          onClose();
          moveReset();
          if (!notClearOnClose) {
            clearForm();
          }
        }}
        onEnter={() => {
          onEnter();
          if (!firstOpen) {
            onMount();
            setFirstOpen(true);
          }
        }}
        {...others}
      >
        <DialogContent className={classes.dialogContent}>
          {header && header(actionParams)}
          <Carousel index={currentIndex} isNext={act === 'next'}>
            {steps.map((stepProps, index) => (
              <DialogStep
                key={stepProps.id}
                index={index}
                currentIndex={currentIndex}
                {...stepProps}
              />
            ))}
          </Carousel>
        </DialogContent>
        <DialogActions
          style={{
            margin: '8px 16px 24px',
            justifyContent:
              currentIndex > 0 && !hideBackButton
                ? 'space-between'
                : 'flex-end',
          }}
        >
          {currentIndex > 0 && !hideBackButton && (
            <Button
              className={classes.button}
              disabled={communicating || disableSubmit}
              onClick={() => {
                if (currentIndex > 0) {
                  movePrev();
                }
              }}
            >
              Voltar
            </Button>
          )}
          {actions && actions(actionParams)}
          <Collapse in={!(isLastStep && hideSubmit)}>
            <SubmitButton
              color="primary"
              align="right"
              className={classes.button}
              disabled={communicating || disableSubmit}
              loading={isSubmitting}
              onClick={() => {
                if (!isLastStep) {
                  moveNext();
                  return;
                }

                showErrors(hasError);
                if (!hasError) {
                  if (submit) {
                    doSubmit();
                  } else {
                    onSubmit();
                  }
                }
              }}
            >
              {renderTextButton(submit, submitText, isLastStep)}
            </SubmitButton>
          </Collapse>
        </DialogActions>
      </Dialog>
    </Form>
  );
}

DialogStepForm.defaultProps = {
  id: 'stepDialogForm',
  validator: null,
  submit: null,
  isSubmitting: false,
  onEnter: () => null,
  onMount: () => null,
  doSubmit: () => null,
  onSubmit: () => null,
  onChangeStep: () => null,
  actions: null,
  dialogActions: [],
  header: null,
  submitText: 'Salvar',
  disableSubmit: false,
  hideSubmit: false,
  hideBackButton: false,
  notClearOnClose: false,
};

DialogStepForm.propTypes = {
  /*
   * id do `Form`.
   */
  id: PropTypes.string,

  /*
   * A prop view é passada para o formulário.
   */
  view: PropTypes.string.isRequired,

  /*
   * A prop validator é passada para o formulário.
   */
  validator: PropTypes.func,

  /*
   * Se o dialog deve ser aberto.
   */
  open: PropTypes.bool.isRequired,

  /*
   * Função chamada ao tentar fechar o Dialog.
   */
  onClose: PropTypes.func.isRequired,

  /*
   * Flag que sinaliza se ao fechar a janela o formulário deve
   * ser limpo.
   */
  notClearOnClose: PropTypes.bool,

  /*
   * Função chamada ao abrir o Dialog.
   */
  onEnter: PropTypes.func,

  /*
   * Função mudar de step.
   */
  onChangeStep: PropTypes.func,

  /*
   * Função chamada apenas na primeira vez que a dialog é aberta.
   */
  onMount: PropTypes.func,

  /*
   * Função chamada ao submeter um formulário.
   */
  onSubmit: PropTypes.func,

  /*
   * Item a ser renderizado antes dos steps e que não precisa ser recarregado
   * ao trocar de step.
   */
  header: PropTypes.func,

  /*
   * Função que renderiza componentes na seção de ações do Dialog.
   */
  actions: PropTypes.func,

  /*
   * Ações passadas para o componente `Dialog`.
   */
  dialogActions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      icon: PropTypes.any.isRequired,
      color: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    }),
  ),

  /*
   * Função chamada ao submeter um formulário.
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
      validator: PropTypes.func,
      onShow: PropTypes.func,
      onNext: PropTypes.func,
      onBack: PropTypes.func,
    }),
  ).isRequired,

  /*
   * O texto do botão pode ser definido por aqui ou pelo `buttonText`
   * no submit. No caso, o valor do submit sobreescreve este valor.
   */
  submitText: PropTypes.string,

  /*
   * Flag para colocar o botão de submit em modo de carregamento.
   */
  isSubmitting: PropTypes.bool,

  /*
   * Desabilita o botão de submit.
   */
  disableSubmit: PropTypes.bool,

  /*
   * Esconde o botão de submit.
   */
  hideSubmit: PropTypes.bool,

  /*
   * Esconde o botão de voltar.
   */
  hideBackButton: PropTypes.bool,

  /*
   * Objeto Submit que será chamado ao submete o formulário.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  submit: PropTypes.instanceOf(Submit),

  /*
   * Função que executa o submit.
   */
  doSubmit: PropTypes.func,
};

export default withStyles(styles)(DialogStepForm);
