import { makeStyles } from "@material-ui/styles";

import { colors, theme } from "../../theme";

const xsDown = theme.breakpoints.down("xs");
const smDown = theme.breakpoints.down("sm");
const mdDown = theme.breakpoints.down("md");

export const useStyles = makeStyles({
  doughnutContainer: {
    width: "100%",

    padding: "1rem",

    // [mdDown]: {
    //   marginBottom: 15,
    // },
    // [smDown]: {
    //   marginBottom: 25,
    // },
    // [xsDown]: {
    //   marginBottom: 35,
    // },
  },
  barContainer: {
    display: "flex",
    width: "100%",
    height: "20rem",
    padding: "1rem",
  },
  labelContainer: {
    height: "50px",
    width: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    bottom: "105px",
    margin: "auto",
    textAlign: "center",
    position: "relative",
    alignItems: "center",
    marginBottom: "-30px",
    [mdDown]: {
      bottom: "155px",
    },
    [smDown]: {
      bottom: "155px",
    },
    [xsDown]: {
      bottom: "155px",
    },
  },
  labelContainerEmpty: {
    height: "50px",
    display: "flex",
    justifyContent: "center",
    bottom: "135px",
    width: "50px",
    margin: "auto",
    textAlign: "center",
    position: "relative",
    alignItems: "center",
    marginBottom: "-30px",
    color: colors.veryDarkBlue,
    [mdDown]: {
      bottom: "155px",
    },
    [smDown]: {
      bottom: "155px",
    },
    [xsDown]: {
      bottom: "155px",
    },
  },
  label: {
    fontSize: "1.2rem",
    fontWeight: 800,
    "&:hover": {
      opacity: 1,
    },
    [smDown]: {
      fontSize: "35px",
      paddingTop: "8px",
    },
    [xsDown]: {
      fontSize: "28px",
      padding: "0 0 16px 0",
    },
  },
  labelMessage: {
    fontSize: "1rem",
    fontWeight: 500,
    "&:hover": {
      opacity: 1,
    },
    [smDown]: {
      fontSize: "35px",
      paddingTop: "8px",
    },
    [xsDown]: {
      fontSize: "28px",
      padding: "0 0 16px 0",
    },
  },
  labelEmpty: {
    fontSize: "1rem",
    fontWeight: 400,
    "&:hover": {
      opacity: 1,
    },
    [smDown]: {
      fontSize: "1.2rem",
      paddingTop: "8px",
    },
    [xsDown]: {
      fontSize: "1rem",
      padding: "0 0 16px 0",
    },
  },
  description: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "1rem",

    padding: "1rem 2rem 1rem 2rem",
    color: colors.veryDarkBlue,
  },

  descriptionText: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    gap: ".5rem",
    marginBottom: "1rem",

    "& span": { fontSize: "1rem" },
  },

  dot: {
    borderRadius: "50%",
    width: ".6rem",
    height: ".6rem",
  },

  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5rem 0 5rem 0",
  },

  title: {
    display: "flex",
    alignItems: "center",
    padding: "1rem 2rem 0 2rem",
  },

  titleText: {
    fontSize: "1.2rem",
    color: colors.veryDarkBlue,
    [theme.breakpoints.down("sm")]: {
      fontSize: "90%",
      marginBottom: "0.8rem",
    },
  },
});
