import { withStyles } from "@material-ui/core/styles";
import React from "react";

import styleCommons from "./style";

function styles(theme) {
  const enterT = theme.transitions.duration.enteringScreen;
  const leaveT = theme.transitions.duration.leavingScreen;
  const sharpT = theme.transitions.easing.sharp;
  const createT = theme.transitions.create;
  const lgUp = theme.breakpoints.up("lg");
  const { closedDrawerWidth: closedDrawerW, drawerWidth: drawerW } = styleCommons(theme);

  const calcW = (value) => `calc(100% - ${value}px)`;

  return {
    dashboard: {
      margin: 0,
      width: "100%",
      transition: createT(["width", "margin"], {
        duration: enterT,
        easing: sharpT,
      }),
      [lgUp]: {
        width: calcW(closedDrawerW),
        marginLeft: closedDrawerW,
      },
    },
    smallDashboard: {
      margin: 0,
      width: "100%",
      transition: createT(["width", "margin"], {
        duration: leaveT,
        easing: sharpT,
      }),
      [lgUp]: {
        width: calcW(drawerW),
        marginLeft: drawerW,
      },
    },
  };
}

function RightPanel(props) {
  const { drawerOpen, classes, children } = props;
  const className = drawerOpen ? classes.smallDashboard : classes.dashboard;

  return <div className={className}>{children}</div>;
}

export default React.memo(withStyles(styles)(RightPanel));
