import React from 'react';
import { Grid, Button, Popover } from '@material-ui/core';
import Input from '../Input';
import { tabNumber, tabByNumber, lastOnesOptions } from './consts';
import { getRange, initialDateInputProps } from './props';
import TabsPanel from './TabsPanel';

function FilterPopover(props) {
  const {
    id,
    open,
    anchor,
    classes,
    onClose,
    commitState,
    tab,
    date,
    endDate,
    lastOnesOption,
  } = props;
  const [currentTab, setCurrentTab] = React.useState(tab);
  const [currentDate, setCurrentDate] = React.useState(date);
  const [currentEndDate, setCurrentEndDate] = React.useState(endDate);
  const [currentOption, setCurrentOption] = React.useState(lastOnesOption);
  const [newDate, newEndDate] = getRange(
    currentTab,
    currentDate,
    currentEndDate,
    currentOption,
  );

  const tabIdx = tabNumber[currentTab];
  const onFilter = commitState({
    tab: currentTab,
    option: currentOption,
    endDate: newEndDate,
    date: newDate,
  });

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={classes.popover}
    >
      <TabsPanel
        currentTab={tabIdx}
        onChange={(event, index) => setCurrentTab(tabByNumber[index])}
      />
      <Grid container spacing={2} className={classes.formBody}>
        {tabIdx === 0 && (
          <>
            <Grid item xs={12}>
              <Input
                type="radio"
                options={lastOnesOptions}
                label="Útimos"
                value={currentOption}
                onChange={(e) => setCurrentOption(e.target.value)}
              />
            </Grid>
          </>
        )}
        {tabIdx >= 1 && (
          <Grid item xs={12}>
            <Input
              type="date"
              style={{ width: '100%' }}
              {...initialDateInputProps(currentTab, setCurrentDate)}
              value={currentDate}
              noKeyboard
            />
          </Grid>
        )}
        {tabIdx === 3 && (
          <Grid item xs={12}>
            <Input
              label="Até"
              type="date"
              onChange={(e) => setCurrentEndDate(e)}
              value={currentEndDate}
              noKeyboard
            />
          </Grid>
        )}
      </Grid>
      <div className={classes.formButtons}>
        <Button color="default" size="medium" onClick={onClose}>
          Fechar
        </Button>
        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={(e) => {
            onFilter(e);
            onClose();
          }}
        >
          <b>Filtrar</b>
        </Button>
      </div>
    </Popover>
  );
}

export default FilterPopover;
