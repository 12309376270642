import {
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";

import { processCombo } from "../../core/util";
import { theme } from "../../theme";

function styles(theme) {
  return {
    root: {
      flexGrow: 1,
    },
    input: {
      minWidth: "0px !important",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
    tag: {
      maxWidth: "calc(100% - 24px) !important",
    },
  };
}

function getLabel(option) {
  return typeof option === "string" ? option : option.label;
}

function Autocomplete(props) {
  const {
    id,
    label,
    error,
    style,
    onType,
    onBlur,
    onFocus,
    classes,
    options,
    disabled,
    onChange,
    mainOption,
    helperText,
    multiselect,
    InputProps,
    placeholder,
    InputLabelProps,
    value: oldValue,
  } = props;

  const [value, setValue] = React.useState([]);
  const customOption = mainOption ? options && options.insert(0, Map(mainOption)) : options;

  React.useEffect(() => {
    if (oldValue) {
      if (List.isList(oldValue)) {
        if (oldValue.size > 0) {
          setValue(processCombo(oldValue).toJS());
        } else {
          setValue([]);
        }
      } else if (Map.isMap(oldValue)) {
        if (oldValue.size > 0) {
          setValue(processCombo(List([oldValue])).toJS());
        } else {
          setValue([]);
        }
      } else {
        setValue(oldValue);
      }
    } else {
      setValue([]);
    }
  }, [oldValue]);

  return (
    <div className={classes.root} style={style}>
      <MuiAutocomplete
        id={id}
        freeSolo
        multiple
        blurOnSelect
        classes={{ input: classes.input, tag: classes.tag }}
        disableClearable
        getOptionLabel={getLabel}
        options={customOption ? customOption.toJS() : []}
        getOptionDisabled={(option) => option.icon === customOption.toJS()[0].label}
        onChange={(x, y) => {
          if (mainOption) {
            const optionAdd = y.find((option) => option.icon);
            if (optionAdd) {
              const indAdd = y.indexOf(optionAdd);
              y.splice(indAdd, 1);
            }
          }
          if (!multiselect && y.length > 1) {
            onChange(x, y.slice(1));
          } else {
            onChange(x, y);
          }
        }}
        value={value}
        getOptionSelected={(option, value) =>
          isNaN(option.value)
            ? option.value === value.value
            : Number(option.value) === Number(value.value)
        }
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        onInputChange={onType}
        noOptionsText="Sem opções disponíveis"
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Tooltip key={option.label} title={option.label}>
              <Chip
                variant="outlined"
                label={option.label}
                {...getTagProps({ index })}
                deleteIcon={<Close />}
                onDelete={disabled ? undefined : { ...getTagProps({ index }) }.onDelete}
              />
            </Tooltip>
          ))
        }
        renderOption={(option, { inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);
          const { label, onClick, icon: Icon } = option;

          return Icon ? (
            <ListItem dense onClick={onClick} style={{ padding: 0, margin: 0 }}>
              <ListItemIcon style={{ padding: 0, margin: 0 }}>
                <Icon
                  style={{
                    color: "#fff",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "50%",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="subtitle1">{label}</Typography>}
                style={{ paddingLeft: "12px" }}
              />
            </ListItem>
          ) : (
            <div>
              {parts.map((part) => (
                <span
                  key={part.text}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        renderInput={({ onType, ...params }) => (
          <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            InputLabelProps={InputLabelProps}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: InputProps && InputProps.endAdornment,
            }}
          />
        )}
        style={value.length > 0 ? { margin: "8px 16px 8px 0px" } : { margin: "16px 16px 8px 0px" }}
      />
    </div>
  );
}

Autocomplete.defaultProps = {
  value: Map({}),
  multiselect: false,
  options: List(),
  onType: null,
};

Autocomplete.propTypes = {
  multiselect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onType: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.number, PropTypes.string]),
  options: PropTypes.instanceOf(List),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Autocomplete);
