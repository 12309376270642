// @TODO Update: remover esse componente para uma pasta própria
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  withStyles, // @TODO Update: um milagre isso não ter dado pau
  // @TODO Update: vai virar hook useMatchWidth (criar hook);
  withWidth, // @TODO Update: um milagre isso não ter dado pau
} from '@material-ui/core';

// @TODO Update: Material UI migration hotspot - vai virar hook
function styles(theme) {
  const xsDown = theme.breakpoints.down('xs');
  return {
    field: {
      fontSize: 14,
      [xsDown]: {
        marginBottom: 8,
      },
    },
    detail: {
      fontSize: 16,
      [xsDown]: {
        fontSize: 12,
      },
    },
    firstRow: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    value: {
      fontSize: 24,
    },
    bold: {
      fontWeight: 'bold',
    },
    button: {
      padding: '8px 32px',
      margin: '16px auto',
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.light,
    },
    bluePanel: {
      backgroundColor: '#e6f7fc',
      borderRadius: '15px',
      margin: '16px auto',
      padding: '16px 32px',
      textAlign: 'center',
    },
  };
}

// @TODO Update: remover componente para um arquivo próprio
function RowDesktop(props) {
  const { field, value, detail, classes, bold, hideTopLine } = props;

  const cellClass = hideTopLine ? null : classes.firstRow;

  return (
    <TableRow>
      <TableCell align="left" className={cellClass}>
        <Typography
          color="primary"
          className={classNames(classes.field, bold && classes.bold)}
        >
          {field}
        </Typography>
      </TableCell>
      {detail ? (
        <TableCell align="center" padding="none" className={cellClass}>
          <Typography
            color="initial"
            className={classNames(classes.detail, bold && classes.bold)}
          >
            <i>{detail}</i>
          </Typography>
        </TableCell>
      ) : (
        <TableCell align="center" className={cellClass} />
      )}
      <TableCell align="right" className={cellClass}>
        <Typography
          color="primary"
          className={classNames(classes.value, bold && classes.bold)}
        >
          {value}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

// @TODO Update: remover componente para um arquivo próprio
function RowMobile(props) {
  const { field, value, detail, classes, bold, index } = props;
  const cellClass = index === 0 ? classes.firstRow : null;
  return (
    <TableRow>
      <TableCell align="center" className={cellClass}>
        <Typography
          color="primary"
          className={classNames(classes.text, bold && classes.bold)}
        >
          {field}
        </Typography>
        {detail && (
          <Typography color="initial" className={classes.detail}>
            <i>{detail}</i>
          </Typography>
        )}
        <Typography
          color="primary"
          className={classNames(classes.value, bold && classes.bold)}
        >
          {value}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function DisplayTable(props) {
  const { classes, width, style, content, className } = props;

  // @TODO Update: vai virar hook useMatchWidth (criar hook)
  const isMobile = ['xs', 'sm'].includes(width);
  const Row = isMobile ? RowMobile : RowDesktop;
  return (
    <Table style={style} className={className}>
      <TableBody>
        {content.map((rowProps, index) => (
          <Row
            key={rowProps.field}
            classes={classes}
            index={index}
            {...rowProps}
          />
        ))}
      </TableBody>
    </Table>
  );
}

DisplayTable.defaultProps = {
  style: null,
  className: null,
};

DisplayTable.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      hideTopLine: PropTypes.bool,
      detail: PropTypes.string,
      value: PropTypes.string,
      bold: PropTypes.bool,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(withWidth()(DisplayTable));
