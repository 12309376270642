function DialogStep(props) {
  const { index, currentIndex, render } = props;
  if (index !== currentIndex) {
    return null;
  }

  return render();
}

export default DialogStep;
