import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Avatar from '@material-ui/core/Avatar';
import { colors } from '../../theme';

const avatarState = {
  ACTIVE: 'ativo',
  DISABLED: 'desabilitado',
  ERROR: 'erro',
  DONE: 'feito',
};

function styles(theme) {
  const u = theme.spacing(1);
  return {
    avatar: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      margin: 0,
      marginRight: 2 * u,
      width: 30,
      height: 30,
    },
    done: {
      backgroundColor: colors.success,
    },
    active: {
      backgroundColor: theme.palette.primary.light,
    },
    error: {
      backgroundColor: theme.palette.secondary.main,
    },
  };
}

function getAvatarState(params) {
  const { hasValidationError, isDone, isCurrentStep, isActive } = params;

  if (hasValidationError && ((isActive && !isCurrentStep) || isDone)) {
    return avatarState.ERROR;
  }
  if (isCurrentStep || (isActive && !hasValidationError)) {
    return avatarState.ACTIVE;
  }
  if (isDone) {
    return avatarState.DONE;
  }
  return avatarState.DISABLED;
}

function StepAvatar(props) {
  const { index, classes, communicating } = props;

  const currentState = getAvatarState(props);

  switch (currentState) {
    case avatarState.ACTIVE:
      return (
        <Avatar
          className={classNames(
            classes.avatar,
            communicating ? null : classes.active,
          )}
        >
          {index + 1}
        </Avatar>
      );
    case avatarState.ERROR:
      return (
        <Avatar
          className={classNames(
            classes.avatar,
            communicating ? null : classes.error,
          )}
        >
          <Clear />
        </Avatar>
      );
    case avatarState.DONE:
      return (
        <Avatar
          className={classNames(
            classes.avatar,
            communicating ? null : classes.done,
          )}
        >
          <Check />
        </Avatar>
      );
    default:
      return <Avatar className={classes.avatar}>{index + 1}</Avatar>;
  }
}

export default withStyles(styles)(StepAvatar);
