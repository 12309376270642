import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { colors } from '../theme';

const useStyles = makeStyles({
  mainBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    marginRight: '3rem',
    minHeight: '32rem',
    padding: '0 3rem',
  },
  imageBox: {
    margin: '3rem 2rem',
  },
  titleLeftBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    color: colors.veryDarkBlue,
    font: '1rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    lineHeight: '20px',
  },
  title: {
    fontSize: '2rem',
    letterSpacing: '0.12em',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    lineHeight: '37px',
    color: colors.blueLight,
  },
  mainTitleBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
  },
  titleLine: {
    height: '100%',
    width: '2px',
    marginRight: '1rem',
    borderRadius: '13px',
    backgroundColor: '#0093E9',
  },
  rightBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '38rem',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    minHeight: '32rem',
  },
  tileRightBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '3rem',
    '& h1': {
      color: colors.blueLight,
      lineHeight: '16px',
      margin: '1.8rem 0 0 2.5rem',
    },
  },
  options: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    marginBottom: '2rem',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    },
  },
  optionsBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2rem 4rem',
  },
  titleOptions: {
    padding: '1rem 2rem',
    '& h2': {
      display: 'flex',
      alignItems: 'center',
      margin: '0 0 1.5rem 0',
      color: colors.blueLight,
      lineHeight: '15px',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#0093E9',
      lineHeight: '12px',
    },
  },
  button: {
    marginRight: '1.5rem',
    color: '#ffffff',
    height: '2rem',
    width: '2rem',
    minWidth: '0',
    minHeight: '0',
    borderRadius: '50%',
    background: 'linear-gradient(90.35deg, #0076E5 0.27%, #009FCD 99.67%)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    transition: '2s',
    '&:hover': {
      transition: '2s',
      background: 'linear-gradient(90.35deg, #0365c0 0.27%, #038fb6 99.67%)',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.311)',
    },
  },
});

export function DoubleContainers2(props) {
  const {
    illustrateImage,
    littleText,
    greatText,
    Title,
    content,
    // withButton,
    // ButtonPrimary,
    // ButtonSecondary,
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.leftBox}>
        <Box className={classes.imageBox}>
          <img src={illustrateImage} alt="Imagem" />
        </Box>
        <Box className={classes.mainTitleBox}>
          <Box className={classes.titleLine} />
          <Box className={classes.titleLeftBox}>
            <Box className={classes.subtitle}>{littleText}</Box>
            <Box className={classes.title}>{greatText}</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightBox}>
        <Box className={classes.tileRightBox}>
          <h1>{Title}</h1>
        </Box>
        <Box className={classes.optionsBox}>{content}</Box>
      </Box>
    </Box>
  );
}
