// @TODO Update: avaliar a necessidade deste componente
// @TODO Update: Material UI migration hotspot - vai virar hook
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '../theme';

function styles(theme) {
  return {
    defaultOutlinedColor: {
      color: theme.palette.grey[500],
      borderColor: theme.palette.grey[500],
    },
    primaryOutlinedColor: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    secondaryOutlinedColor: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    tertiaryOutlinedColor: {
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
    },
    successOutlinedColor: {
      color: colors.success,
      borderColor: colors.success,
    },
    warningOutlinedColor: {
      color: colors.warning,
      borderColor: colors.warning,
    },
    dangerOutlinedColor: {
      color: colors.secondary,
      borderColor: colors.secondary,
    },
    defaultDefaultColor: {
      color: '#fff',
      backgroundColor: theme.palette.grey[500],
    },
    primaryDefaultColor: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    secondaryDefaultColor: {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },
    tertiaryDefaultColor: {
      color: '#fff',
      backgroundColor: theme.palette.text.primary,
    },
    successDefaultColor: {
      color: '#fff',
      backgroundColor: colors.success,
    },
    warningDefaultColor: {
      color: '#fff',
      backgroundColor: colors.warning,
    },
    dangerDefaultColor: {
      color: '#fff',
      backgroundColor: colors.secondary,
    },
    noPointerEvents: {
      pointerEvents: 'none',
    },
    deleteIcon: {
      fontSize: '16px',
      marginRight: '12px',
    },
  };
}

function ChipTag(props) {
  const {
    label,
    color,
    classes,
    variant,
    onDelete,
    deleteIcon,
    ...others
  } = props;
  const noEvents = deleteIcon && !onDelete ? classes.noPointerEvents : null;
  const fixedColor = color === 'default' ? color : 'primary';

  return (
    <Chip
      color={fixedColor}
      classes={{
        deleteIcon: classNames(
          classes[`${color}Color`],
          noEvents,
          classes.deleteIcon,
        ),
        outlinedPrimary: classes[`${color}OutlinedColor`],
        colorPrimary: classes[`${color}DefaultColor`],
      }}
      label={label}
      variant={variant}
      deleteIcon={deleteIcon}
      onDelete={deleteIcon && !onDelete ? () => null : onDelete}
      {...others}
    />
  );
}

ChipTag.defaultProps = {
  variant: 'outlined',
  color: 'default',
  deleteIcon: null,
  onDelete: null,
};

ChipTag.propTypes = {
  variant: PropTypes.oneOf(['default', 'outlined']),
  label: PropTypes.string.isRequired,
  deleteIcon: PropTypes.element,
  onDelete: PropTypes.func,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'warning',
    'danger',
    'success',
    'default',
  ]),
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(ChipTag);
