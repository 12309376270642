import {
  AccountBalance,
  AccountBalanceWallet,
  ArrowForwardIos,
  Assignment,
  BugReport,
  Contacts,
  Email,
  Event,
  ExitToApp,
  Group,
  GroupWork,
  History,
  HowToVote,
  LibraryBooks,
  LiveHelp,
  Menu as MenuIcon,
  MonetizationOn,
  Notifications,
  OpenInNew,
  Payment,
  PlaylistPlay,
  Policy,
  Poll,
  QuestionAnswer,
  Receipt,
  School,
  Settings,
  Store,
  Style,
  ViewList,
  ViewQuilt,
  WrapText,
} from "@material-ui/icons";
import {
  mdiAccount,
  mdiAccountSchool,
  mdiAccountTie,
  mdiBankTransfer,
  mdiBriefcasePlus,
  mdiCashMultiple,
  mdiCashRefund,
  mdiCashRegister,
  mdiClipboardOutline,
  mdiCounter,
  mdiGoogleClassroom,
  mdiPartyPopper,
  mdiSack,
  mdiSchool,
  mdiShape,
  mdiSignatureFreehand,
} from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

function PartyPopper(props) {
  const { white = false } = props;
  return <Icon path={mdiPartyPopper} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function Formando(props) {
  const { white = false } = props;

  return <Icon path={mdiAccountSchool} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function Formatura(props) {
  const { white = false } = props;
  return <Icon path={mdiSchool} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function Sack(props) {
  const { white = false } = props;
  return <Icon path={mdiSack} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function SignatureFreehand(props) {
  const { white = false } = props;
  return <Icon path={mdiSignatureFreehand} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function GoogleClassroom(props) {
  const { white = false } = props;
  return <Icon path={mdiGoogleClassroom} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function ClipBoard(props) {
  const { white = false } = props;
  return <Icon path={mdiClipboardOutline} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function CashRegister(props) {
  const { white = false } = props;
  return <Icon path={mdiCashRegister} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function CashMultiple(props) {
  const { white = false } = props;
  return <Icon path={mdiCashMultiple} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function PiggyBank(props) {
  const { white = false } = props;
  return (
    // <Icon path={mdiPiggyBank} size={1} color={white ? '#fff' : '#5C93AD'} />
    <img
      width="24"
      height="24"
      alt="vaquinhas"
      src={white ? "/img/icons/vaquinha_menu2.svg" : "/img/icons/vaquinha_menu1.svg"}
    />
  );
}

function CashRefund(props) {
  const { white = false } = props;
  return <Icon path={mdiCashRefund} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function BankTransfer(props) {
  const { white = false } = props;
  return <Icon path={mdiBankTransfer} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function BriefcasePlus(props) {
  const { white = false } = props;
  return <Icon path={mdiBriefcasePlus} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function Account(props) {
  const { white = false } = props;
  return <Icon path={mdiAccount} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function AccountTie(props) {
  const { white = false } = props;
  return <Icon path={mdiAccountTie} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function Counter(props) {
  const { white = false } = props;
  return <Icon path={mdiCounter} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

function Shape(props) {
  const { white = false } = props;
  return <Icon path={mdiShape} size={1} color={white ? "#fff" : "#5C93AD"} />;
}

const iconSet = {
  AccountBalanceWallet,
  AccountBalance,
  QuestionAnswer,
  MonetizationOn,
  Notifications,
  PlaylistPlay,
  LibraryBooks,
  Assignment,
  BugReport,
  OpenInNew,
  ExitToApp,
  ViewQuilt,
  HowToVote,
  GroupWork,
  Settings,
  WrapText,
  ViewList,
  LiveHelp,
  Contacts,
  Payment,
  Receipt,
  History,
  School,
  Email,
  Group,
  Event,
  Style,
  Store,
  Poll,
  Policy,
  Formando,
  Formatura,
};

const mdiIconSet = {
  SignatureFreehand,
  GoogleClassroom,
  BriefcasePlus,
  BankTransfer,
  CashRegister,
  CashMultiple,
  PartyPopper,
  AccountTie,
  CashRefund,
  PiggyBank,
  ClipBoard,
  Account,
  Counter,
  Shape,
  Sack,
};

function loadIcon(icon, hasSubmenu, white) {
  const Icon = iconSet[icon];
  if (Icon) {
    return <Icon white={white} />;
  }

  const MdiIcon = mdiIconSet[icon];
  if (MdiIcon) {
    return <MdiIcon white={white} />;
  }

  return hasSubmenu ? <MenuIcon /> : <ArrowForwardIos />;
}

function getMenuIcon(icon, hasSubmenu, noDefaultIcon, white) {
  if (icon) {
    if (typeof icon === "string") {
      return loadIcon(icon, hasSubmenu, white);
    }
    return icon;
  }
  if (noDefaultIcon) {
    return null;
  }
  return hasSubmenu ? <MenuIcon /> : <ArrowForwardIos />;
}

export default getMenuIcon;
