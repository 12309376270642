import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  LinearProgress,
  Collapse,
} from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
// @TODO Update: vai virar hook useMatchWidth (criar hook)
import withWidth from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line max-len
import DrawerAction from '../templates/_internal/TableWrapper/Detail/DrawerAction';

function getMargin(isSmallScreen, headless) {
  if (headless) {
    return 0;
  }
  if (isSmallScreen) {
    return 8;
  }
  return 16;
}

function splitActions(actions) {
  if (!actions) {
    return [[], []];
  }
  const leftActions = actions.filter((act) => act.beforeTitle);
  const rightActions = actions.filter((act) => !act.beforeTitle);
  return [leftActions, rightActions];
}

// TODO ajeitar essa atrocidade!!! Veja o histórico do arquivo para
//      entender a merda que foi feita! VIOLAÇÃO DA REGRA DE USO DE
//      HOOKS.
function useHeaderRenderer(
  title,
  actions,
  onClose,
  isSmallScreen,
  headless,
  loading,
) {
  const marginBottom = getMargin(isSmallScreen, headless);
  const [leftActions, rightActions] = React.useMemo(
    () => splitActions(actions),
    [actions],
  );

  return () => (
    <>
      <AppBar
        elevation={0}
        style={{
          position: 'relative',
          marginBottom,
        }}
      >
        <Toolbar>
          {title && typeof title === 'string' ? (
            <div style={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                color="inherit"
                display="inline"
                style={{
                  fontSize:
                    title.length > 20 && isSmallScreen ? '0.8rem' : '1.25rem',
                }}
              >
                <DrawerAction actions={leftActions} tuple={null} />
                <b>{title}</b>
                <DrawerAction actions={rightActions} tuple={null} />
              </Typography>
            </div>
          ) : (
            <div style={{ flexGrow: 1 }}>{title}</div>
          )}
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Collapse
          in={loading}
          style={{ width: '100%' }}
          mountOnEnter
          unmountOnExit
        >
          <LinearProgress />
        </Collapse>
      </AppBar>
    </>
  );
}

/**
 * [Dialog](https://material-ui.com/demos/dialogs/) personalizado para
 * o Sua Formatura.
 *
 * Diferenciais:
 * - tem um título por padrão;
 * - tem um botão de fechar no canto direito superior;
 * - em telas pequenas exibe o título e o botão de fechar
 *   em uma [AppBar](https://material-ui.com/demos/app-bar/).
 * - é [fullWidth](https://material-ui.com/api/dialog/) por padrão
 *
 * @param {object} props
 */
function Dialog(props) {
  const {
    id,
    open,
    title,
    width,
    onClose,
    actions,
    loading,
    headless,
    children,
    ...others
  } = props;
  // @TODO Update: vai virar hook useMatchWidth (criar hook)
  const isSmallScreen = width === 'xs' || width === 'sm';
  const renderHeader = useHeaderRenderer(
    title,
    actions,
    onClose,
    isSmallScreen,
    headless,
    loading,
  );

  return (
    <MuiDialog
      id={id}
      fullWidth
      open={open}
      scroll="body"
      onClose={onClose}
      fullScreen={isSmallScreen}
      {...others}
    >
      {renderHeader(title, actions, onClose, isSmallScreen, headless, loading)}
      {children}
    </MuiDialog>
  );
}

Dialog.defaultProps = {
  actions: [],
  title: null,
  open: false,
  children: null,
  headless: false,
  loading: false,
};

Dialog.propTypes = {
  /**
   * ID no `Dialog` que será passada para o `DOM`.
   */
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  open: PropTypes.bool,
  headless: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      icon: PropTypes.any.isRequired,
      color: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      beforeTitle: PropTypes.bool,
    }),
  ),
  children: PropTypes.oneOfType([PropTypes.any]),
};

// @TODO Update: vai virar hook useMatchWidth (criar hook)
export default withWidth()(Dialog);
