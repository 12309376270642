import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar as AvatarIcon, ListItemAvatar } from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';

const DEFAULT_IMG_PATH = '/img/avatar-default.png';

function styles(theme) {
  const u = theme.spacing(1);

  return {
    avatar: {
      marginLeft: u * -0.5,
    },
  };
}

function ItemAvatar(props) {
  const { avatar, avatarLetter, drawerOpen, classes } = props;
  const hasAvatar = Boolean(avatar && avatar.length < 5);
  const [url, setUrl] = React.useState(null);

  React.useEffect(() => {
    if (avatar) {
      setUrl(avatar);
    }
  }, [avatar]);

  return (
    <ListItemAvatar className={drawerOpen ? classes.avatar : ''}>
      <AvatarIcon
        src={url || DEFAULT_IMG_PATH}
        onError={(e) => {
          if (!e.target.src.endsWith(DEFAULT_IMG_PATH)) {
            setUrl(DEFAULT_IMG_PATH);
          }
        }}
        style={
          !hasAvatar
            ? null
            : { backgroundColor: deepOrange[500], height: '100%' }
        }
      >
        {hasAvatar ? avatarLetter : null}
      </AvatarIcon>
    </ListItemAvatar>
  );
}

export default withStyles(styles)(ItemAvatar);
