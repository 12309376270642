import React from 'react';
import classNames from 'classnames';
import { ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ellipsis } from '../../../core/util/index';

function styles(theme) {
  const { light: primary } = theme.palette.primary;

  return {
    root: {
      flexGrow: 1,
      color: '#ffD3F4',
    },
    noPadding: {
      padding: 0,
    },
    inset: {
      padding: 0,
      '&:first-child': {
        paddingLeft: '24px',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    primary: {
      fontSize: '0.875rem',
      color: theme.palette.primary.main,
    },
    typography: {
      fontSize: '0.875rem',
      color: '#5C93AD',
    },
    open: {
      fontWeight: 700,
      color: primary,
    },
    currentPage: {
      fontWeight: 700,
    },
  };
}

function getListItemTextPrimaryClasses(params) {
  const {
    open,
    classes,
    hasSubmenu,
    isCurrentPage,
    depth,
    bold,
    isUserName,
  } = params;
  if (hasSubmenu) {
    return classNames(
      isUserName ? classes.primary : classes.typography,
      bold && classes.bold,
      open && classes.open,
    );
  }
  const isSubmenuItem = depth > 0;
  return classNames(
    classes.typography,
    bold && classes.bold,
    isCurrentPage && !isSubmenuItem && classes.open,
    isCurrentPage && isSubmenuItem && classes.currentPage,
  );
}

function getListItemTextRootClasses(depth, hasSubmenu, classes) {
  if (depth === 0) {
    return null;
  }

  if (hasSubmenu && depth > 0) {
    return classes.inset;
  }

  return classes.noPadding;
}

function rederLabel(label, isUserName, depth) {
  if (!isUserName || depth > 0) {
    return label;
  }
  return (
    <>
      Bem-vindo, <b>{ellipsis(label, 15)}</b>
    </>
  );
}

function ItemText(props) {
  const {
    bold,
    label,
    depth,
    classes,
    hasSubmenu,
    isUserName,
    isSubmenuOpen,
    isCurrentPage,
    secondaryLabel = null,
  } = props;

  return (
    <ListItemText
      style={{ flexGrow: 1 }}
      align={depth > 0 ? 'center' : 'inherit'}
      className={classes.root}
      secondary={secondaryLabel}
      primary={rederLabel(label, isUserName, depth)}
      classes={{
        primary: getListItemTextPrimaryClasses({
          open: isSubmenuOpen,
          isCurrentPage,
          hasSubmenu,
          classes,
          depth,
          bold,
          isUserName,
        }),
        root: getListItemTextRootClasses(depth, hasSubmenu, classes),
      }}
    />
  );
}

export default withStyles(styles)(ItemText);
