import { Box } from "@material-ui/core";
import { Bar } from "react-chartjs-2";

export function BarChart(props) {
  const { classes, labels, values, colors, hoverColors, description } = props;
  return (
    <Box>
      <div className={classes.barContainer}>
        <Bar
          data={{
            labels,
            datasets: [
              {
                data: values,
                backgroundColor: colors,
                hoverBackgroundColor: hoverColors,
                minBarLength: 10,
              },
            ],
          }}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },

            maintainAspectRatio: false,
            legend: {
              display: false,
            },

            plugins: {
              labels: {
                render: () => {},
              },
            },

            tooltips: {
              enabled: true,
            },
          }}
        />
      </div>

      {description && (
        <Box className={classes.description}>
          {description.map((item) => (
            <Box key={item.labelDescription} className={classes.descriptionText}>
              <div style={{ backgroundColor: item.dotColor }} className={classes.dot} />
              <span>{item.labelDescription}</span>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
