import moment from 'moment';
import { format } from '../../core/util';
import { days, Choices, rangeLabel } from './consts';

function formatDay(date) {
  return format('date', date);
}

function formatMonth(date) {
  return moment(date).format('MMMM [de] YYYY');
}

function initialDateInputProps(currentTab, setCurrentDate) {
  switch (currentTab) {
    case Choices.LAST:
      return {
        label: 'Últimos',
        onChange: (e) => setCurrentDate(e),
      };
    case Choices.DAILY:
      return {
        label: 'Data',
        onChange: (e) => setCurrentDate(e),
      };
    case Choices.MONTHLY:
      return {
        label: 'Mês',
        views: ['year', 'month'],
        customFormat: 'MMMM [de] YYYY',
        onChange: (e) => setCurrentDate(e),
      };
    default:
      return {
        label: 'Data inicial',
        onChange: (e) => setCurrentDate(e),
      };
  }
}

function getLabel(tab, date, endDate, lastOnesOption) {
  switch (tab) {
    case Choices.LAST:
      return `Últimos ${rangeLabel[lastOnesOption]}`;
    case Choices.PERIODICALLY:
      return `${formatDay(date)} - ${formatDay(endDate)}`;
    case Choices.MONTHLY:
      return formatMonth(date);
    default:
      return formatDay(date);
  }
}

function getRange(tab, date, endDate, option) {
  if (tab === Choices.LAST) {
    const v = days[option];
    const start = moment().subtract(v, 'days').startOf('day').format();
    const end = moment().endOf('day').format();

    return [start, end];
  }

  const dateObj = moment(date);
  switch (tab) {
    case Choices.DAILY:
      return [dateObj.startOf('day').format(), dateObj.endOf('day').format()];
    case Choices.MONTHLY:
      return [
        dateObj.startOf('month').format(),
        dateObj.endOf('month').format(),
      ];
    default:
      return [
        dateObj.startOf('day').format(),
        moment(endDate).endOf('day').format(),
      ];
  }
}

export { initialDateInputProps, getLabel, getRange };
