import { Box, Typography } from "@material-ui/core";
import { Line } from "react-chartjs-2";

import { theme } from "../../theme";

const orderStyles = (descriptionAlign) => {
  if (descriptionAlign === "top") {
    return {
      order: 1,
    };
  }
  return {
    order: 2,
  };
};
const chartOrderStyles = (descriptionAlign, height) => {
  if (descriptionAlign === "top") {
    return {
      order: 2,
      height: height || "12rem",
    };
  }
  return {
    order: 1,
    height: height || "12rem",
  };
};

export function LineChart(props) {
  const { classes, title, labels, descriptionAlign, values, description, height } = props;

  const roundedValues = values.map((value) => Number(value).toFixed(2));

  const allZero = values.every((value) => value === 0);
  const data = allZero
    ? {
        labels: ["None"],
        datasets: [
          {
            data: [1],
            backgroundColor: ["#f0f0f0"],
            hoverBackgroundColor: ["#f0f0f0"],
          },
        ],
      }
    : {
        labels,
        datasets: [
          {
            data: roundedValues,
            label: "Adesão (%)",
            borderColor: "#C0D3FF",
            fill: false,
          },
        ],
      };

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      {title && (
        <Box className={classes.title}>
          <Typography className={classes.titleText}>{title}</Typography>
        </Box>
      )}
      {description && (
        <Box className={classes.description} style={orderStyles(descriptionAlign)}>
          {description.map((item) => (
            <Box key={item.labelDescription} className={classes.descriptionText}>
              <div style={{ backgroundColor: item.dotColor }} className={classes.dot} />
              <span>{item.labelDescription}</span>
            </Box>
          ))}
        </Box>
      )}
      <div className={classes.doughnutContainer} style={chartOrderStyles(descriptionAlign, height)}>
        <Line
          data={data}
          options={{
            scales: {
              xAxes: [
                {
                  gridLines: {
                    lineWidth: 2,
                    display: true,
                    color: "rgba(43, 173, 211, 0.25)",
                  },
                  ticks: {
                    min: 0,
                    fontSize: 14,
                    fontColor: theme.palette.text.primary,
                    fontFamily: '"Montserrat", "Roboto", "Helvetica",' + '"Arial", sans-serif',
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  offset: true,
                  ticks: {
                    beginAtZero: true,
                    callback: (value) => `${value}%`,
                  },
                },
              ],
            },
            plugins: {
              labels: {
                render: () => {},
              },
            },
            legend: {
              display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
    </Box>
  );
}
