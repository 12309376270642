import { Box, LinearProgress, Link, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import Hooks from "../hooks/browser";
import { colors, theme } from "../theme";

const xsDown = theme.breakpoints.down("sm");

export const useStyles = makeStyles({
  cardInfo: {
    fontFamily: '"Montserrat"',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    background: "#ffffff",
    overflow: "hidden",

    "& h1": {
      fontWeight: "600",
      fontSize: "1.5rem",
      margin: "0",
      color: colors.veryDarkBlue,
    },
  },
  cardInfoFull: {
    gridColumn: "1/3",

    [xsDown]: {
      gridColumn: "1/2",
    },
  },
  description: {
    fontSize: ".8rem",
    color: colors.veryDarkBlue,
  },

  button: {
    background: "#ffffff",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
    borderRadius: "1.8rem",
    marginRight: ".8rem",
    color: "#607D8B",
    fontSize: ".6rem",
  },
  buttonActive: {
    background: "#197997",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
    borderRadius: "1.8rem",
    marginRight: ".8rem",
    color: "#ffffff",
    fontSize: ".7rem",
    fontWeight: "800",

    "&:hover": {
      background: "#197997",
      color: "#ffffff",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: ".8rem 1.5rem .8rem 1.5rem",
  },

  footerButton: {
    background: "rgba(12, 111, 141, 1)",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    color: "#ffffff",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    transition: "all .3s",

    "&:hover": {
      background: "#07485c",
      color: "#e8e8e8",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.3)",
      cursor: "pointer",
    },
  },
  boxProgressoLinearLoading: {
    height: "5px",
  },
  progressoLinearLoading: {
    height: "100%",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1.5rem 1.5rem .5rem 1.5rem",

    [xsDown]: {
      flexDirection: "column",
      gap: "1.5rem",
    },
  },
  ButtonContainer: {
    display: "flex",
    gap: "1rem",
  },
});

export function CardInfo(props) {
  const classes = useStyles();

  const {
    body,
    button1,
    button2,
    button3,
    redirect,
    redirectTrex,
    title,
    subtitle,
    isLoading,
    action,
    width,
  } = props;

  const redirecionamento = Hooks.useGo(redirect);

  const str = redirectTrex?.replace("/index.php", "");

  const redirecionamentoTrex = Hooks.useRedirect(str);

  return (
    <Box className={`${classes.cardInfo} ${width === "full" && classes.cardInfoFull}`}>
      <div>
        <Box className={classes.boxProgressoLinearLoading}>
          {isLoading && (
            <LinearProgress className={classes.progressoLinearLoading} color="primary" />
          )}
        </Box>
        <Box className={classes.header}>
          <Box component="section" className={classes.section}>
            <h1>{title}</h1>
            <span className={classes.description}>{subtitle}</span>
          </Box>
          <Box className={classes.ButtonContainer}>
            {button1}
            {button2}
            {button3}
          </Box>
        </Box>
        {body}
      </div>

      {redirect && (
        <Box className={classes.footer}>
          <Link onClick={redirecionamento} className={classes.footerButton}>
            <ArrowForwardIcon />
          </Link>
        </Box>
      )}
      {redirectTrex && (
        <Box className={classes.footer}>
          <Link onClick={redirecionamentoTrex} className={classes.footerButton}>
            <ArrowForwardIcon />
          </Link>
        </Box>
      )}
      {action && (
        <Box className={classes.footer}>
          <Link onClick={action} className={classes.footerButton}>
            <ArrowForwardIcon />
          </Link>
        </Box>
      )}
    </Box>
  );
}
