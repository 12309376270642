import React from 'react';
import { Typography, Button, withStyles } from '@material-ui/core';

function styles(theme) {
  const xsDown = theme.breakpoints.down('xs');

  return {
    root: {
      width: '100%',
    },
    textContainer: {
      marginBottom: '16px',
    },
    button: {
      fontWeight: 'bold',
      padding: '8px 16px',
      margin: '0px 16px',
      minWidth: '150px',
      backgroundColor: theme.palette.primary.light,
      [xsDown]: {
        margin: '10px auto',
      },
    },
    flex: { display: 'flex', justifyContent: 'center' },
    center: {
      width: 'fit-content',
      [xsDown]: {
        padding: '0 50%',
      },
    },
  };
}

function CustomButton(props) {
  const { text, classes, ...others } = props;
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      {...others}
    >
      {text}
    </Button>
  );
}

function InfoStep(props) {
  const {
    ask,
    onNo,
    onYes,
    noText,
    classes,
    message,
    yesText,
    callNext,
    onShowContent,
    communicating,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        {typeof message === 'function' ? (
          message()
        ) : (
          <Typography style={{ textAlign: 'justify' }}>{message}</Typography>
        )}
      </div>
      <div className={classes.flex}>
        <div className={classes.center}>
          <Typography align="center">
            <b>{ask}</b>
          </Typography>
          <CustomButton
            classes={classes}
            text={yesText || 'SIM'}
            disabled={communicating}
            onClick={() => {
              if (onYes) {
                onYes();
              }
              onShowContent();
            }}
          />
          <CustomButton
            classes={classes}
            text={noText || 'NÃO'}
            disabled={communicating}
            onClick={() => {
              if (onNo) {
                onNo();
              }
              callNext();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(InfoStep);
