import React from 'react';
import { Map, List } from 'immutable';
import {
  Box,
  MenuItem,
  TextField,
  Typography,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Dialog from '../Dialog';
import comm from '../../core/comm/actions';
import qcomm from '../../core/comm/queries';
import qcache from '../../core/cache/queries';
import session from '../../core/session/actions';
import { Request, Response } from '../../core/comm';
import { searchRegex } from '../../core/util';
import { TREX_URL } from '../../core/config';

const organizationListURL = '/v1/usuario_organizacoes';

function styles(theme) {
  const u = theme.spacing(1);
  const hpadding = 4 * u;
  const vpadding = 2 * u;

  return {
    list: {
      paddingTop: hpadding,
    },
    progressWrapper: {
      minHeight: 200,
    },
    content: {
      paddingLeft: hpadding,
      paddingRight: hpadding,
      paddingTop: vpadding,
      paddingBottom: vpadding,
    },
  };
}

function transformOrganization(org) {
  return {
    value: org.get('codigo'),
    label: org.get('fantasia'),
    userType: org.get('tipoUsuario'),
    zid: org.get('zid'),
  };
}

function createOrganizationPredicate(search) {
  return (org) => {
    const identification = org.get('identificacao') || '';
    const fname = org.get('fantasia') || '';
    const name = org.get('nome') || '';
    const text = `${identification} ${fname} ${name}`;
    return text.match(search);
  };
}

function filterOrganizationsAndGetProps(term, organizations) {
  if (!organizations || organizations.size < 2) {
    return [];
  }
  if (!term) {
    return organizations.take(10).map(transformOrganization).toArray();
  }

  const search = searchRegex(term, 'gi');
  return organizations
    .filter(createOrganizationPredicate(search))
    .take(10)
    .map(transformOrganization);
}

function OrganizationDialog(props) {
  const {
    open,
    onClose,
    classes,
    history,
    isLoading,
    organizations,
    suspendSession,
    fetchOrganizationList,
    fetchNewOrganizationToken,
  } = props;
  const [orgsProps, setOrgsProps] = React.useState([]);
  const [term, setTerm] = React.useState('');

  React.useEffect(() => {
    if (open) {
      fetchOrganizationList();
    }
  }, [open]);

  React.useEffect(() => {
    if (organizations && organizations.size > 1) {
      setOrgsProps(filterOrganizationsAndGetProps(term, organizations));
    }
  }, [term, organizations]);
  return (
    <Dialog
      id="/suaformatura/components/dashboard/orgbutton/orgdialog/dialog"
      open={open}
      title="Mudar de organização"
      onClose={onClose}
    >
      {isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.progressWrapper}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className={classes.content}>
          <div
            style={{
              textAlign: 'center',
              display: 'inline',
              width: '100%',
            }}
          >
            <TextField
              autoFocus
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              style={{ width: '100%' }}
              placeholder="Exemplo: Contabilidade 2019 - Havard"
              helperText="Digite o nome de uma organização"
            />
          </div>
          <div className={classes.list}>
            {orgsProps.map((props) => (
              <MenuItem
                key={props.value}
                onClick={() => {
                  const { userType, value, zid } = props;
                  const isEnterprise = userType !== 'F';
                  if (!isEnterprise) {
                    history.push('/');
                  }
                  suspendSession();
                  fetchNewOrganizationToken(
                    value,
                    isEnterprise ? `${TREX_URL}/index.php?zid=${zid}` : null,
                  );
                  onClose();
                }}
              >
                <ListItemText
                  primary={
                    <Typography style={{ fontSize: '1rem' }}>
                      {props.label}
                    </Typography>
                  }
                />
              </MenuItem>
            ))}
          </div>
        </div>
      )}
    </Dialog>
  );
}

function fetchOrganizationList() {
  return comm.get(
    Request({
      view: '/',
      url: organizationListURL,
    }),
  );
}

function fetchNewOrganizationToken(organization, link) {
  return comm.get(
    Request({
      view: '/suaformatura/components/dashboard/orgbutton',
      url: '/v1/all/trocar_organizacao/:organization',
      urlParams: Map({ organization }),
      onSuccess: Response({
        dispatch: List([
          () => session.persistToken(),
          () => comm.refresh(link),
        ]),
      }),
    }),
  );
}

function mapStateToProps(state) {
  return {
    organizations: qcache.getData(state, '/', organizationListURL),
    isLoading: qcomm.isLoading(state, '/', organizationListURL),
  };
}

export default connect(mapStateToProps, {
  fetchOrganizationList,
  fetchNewOrganizationToken,
  suspendSession: session.suspend,
})(withStyles(styles)(withRouter(OrganizationDialog)));
