import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { mdiContentCopy } from '@mdi/js';

function CopyToClipboardButton(props) {
  const {
    text,
    icon,
    style,
    label,
    color,
    variant,
    onClick,
    disabled,
    className,
  } = props;

  return (
    <CopyToClipboard text={text}>
      <Button
        color={color}
        style={style}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        className={className}
      >
        {icon}
        {label}
      </Button>
    </CopyToClipboard>
  );
}

CopyToClipboardButton.defaultProps = {
  className: null,
  style: null,
  variant: 'outlined',
  color: 'primary',
  disabled: false,
  icon: <Icon path={mdiContentCopy} size={1} color="rgba(0, 128, 173, 1)" />,
};

CopyToClipboardButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.any]),
};

export default CopyToClipboardButton;
