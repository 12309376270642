import React from 'react';
import { Typography, withStyles, Grow } from '@material-ui/core';
import StepAvatar from './StepAvatar';

function styles(theme) {
  return {
    root: {
      display: 'flex',
      width: '100%',
      paddingRight: '30px',
      alignItems: 'center',
    },
    title: {
      marginRight: '16px',
    },
    titleEditMode: {
      fontWeight: 'bold',
    },
    summary: {
      flexGrow: 1,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  };
}

function getSummary(params) {
  const { summary, isActive, editMode, isCurrentStep } = params;
  if ((editMode && isCurrentStep) || (!isActive && !editMode)) {
    return null;
  }

  return typeof summary === 'function' ? summary() : summary;
}

function Summary(props) {
  const {
    communicating,
    hasValidationError,
    stepProps,
    isMobile,
    editMode,
    classes,
    index,
    isDone,
    isActive,
    isCurrentStep,
  } = props;

  const { summary, title } = stepProps;
  const summaryText = getSummary({
    summary,
    isActive,
    editMode,
    isCurrentStep,
  });

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {!editMode && (
          <StepAvatar
            index={index}
            communicating={communicating}
            hasValidationError={hasValidationError}
            isDone={isDone}
            isActive={isActive}
            isCurrentStep={isCurrentStep}
          />
        )}
        <Typography
          display="inline"
          className={editMode ? classes.titleEditMode : undefined}
        >
          {title && title.toUpperCase()}
        </Typography>
      </div>
      {!isMobile && (
        <div className={classes.summary}>
          <Grow appear in={Boolean(summaryText)}>
            <Typography
              align={editMode ? 'left' : 'center'}
              className={classes.secondaryHeading}
            >
              {summaryText}
            </Typography>
          </Grow>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(Summary);
