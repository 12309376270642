import React from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import { Divider, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import OrganizationDialog from './OrganizationDialog';
import { ellipsis } from '../../core/util';
import styleCommons from './style';
import Menu from './Menu';

const WIDTH = 230;

function styles(theme) {
  const u = theme.spacing(1);
  const lgUp = theme.breakpoints.up('lg');
  const mdDown = theme.breakpoints.down('md');
  const createT = theme.transitions.create;
  const sharpT = theme.transitions.easing.sharp;
  const enterT = theme.transitions.duration.enteringScreen;
  const leaveT = theme.transitions.duration.leavingScreen;
  const { drawerWidth: drawerW, smallDrawerWidth: drawerWS } = styleCommons(
    theme,
  );

  return {
    menuContent: {
      width: 'inherit',
      [mdDown]: {
        overflowY: 'scroll',
      },
    },
    exitButton: {
      width: 'inherit',
      backgroundColor: '#fff',
      position: 'fixed',
      bottom: 0,
      overflow: 'hidden',
      [mdDown]: {
        width: '100%',
      },
    },
    paper: {
      width: drawerW,
      postion: 'relative',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      transition: createT('width', {
        duration: enterT,
        easing: sharpT,
      }),
      [mdDown]: {
        width: drawerWS,
        paddingBottom: 0,
      },
    },
    closed: {
      overflow: 'hidden',
      transition: createT('width', {
        duration: leaveT,
        easing: sharpT,
      }),
      width: u * 7,
      [lgUp]: {
        width: 9 * u,
      },
    },
    wrapper: {
      marginLeft: u * 2,
      marginRight: u * 2,
    },
    toolbar: {
      display: 'flex',
      padding: '0 8px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    bigLogo: {
      [mdDown]: {
        paddingLeft: u,
      },
    },
    smallLogo: {
      paddingTop: u * 2,
      paddingLeft: u * 0.5,
    },
  };
}

function getWidth(show, isLargeScreen) {
  if (show) {
    return isLargeScreen ? WIDTH : 210;
  }
  return 50;
}

function handlers(open, isLargeScreen, setFocused, onToggleDrawer) {
  return {
    onMouseEnter: () => {
      if (isLargeScreen && !open) {
        setFocused(true);
      }
    },
    onMouseLeave: () => {
      if (isLargeScreen && !open) {
        setFocused(false);
      }
    },
    onClose: () => {
      if (!isLargeScreen) {
        onToggleDrawer();
      }
    },
  };
}

function userMenu(userProfile, setDialogOpen) {
  const {
    avatar,
    organization,
    apelido: name,
    numberOfOwnedOrgs,
  } = userProfile;
  const canSwitchOrg = numberOfOwnedOrgs > 1;

  return List([
    Map({
      avatar,
      label: ellipsis(name, 12),
      isUserName: true,
      avatarLetter: name.charAt(0),
      secondaryLabel: ellipsis(organization.get('fantasia'), 14),
      submenu: List([
        canSwitchOrg &&
          Map({
            label: 'Mudar organização',
            onClick: () => setDialogOpen(true),
            isUserName: true,
          }),
        Map({ label: 'Perfil', link: '/perfil/visualizar', isUserName: true }),
        Map({ label: 'Senhas', link: '/senha/mudar', isUserName: true }),
      ]).filter((x) => x),
    }),
  ]);
}

function MenuDrawer(props) {
  const {
    userProfile = {},
    suspendSession,
    onToggleDrawer,
    isLargeScreen,
    open,
    menu,
  } = props;
  const { classes, ...rest } = props;
  const [isFocused, setFocused] = React.useState(false);
  const [dialogIsOpen, setDialogOpen] = React.useState(false);

  const show = open || isFocused;
  const w = getWidth(show, isLargeScreen);

  const { numberOfOwnedOrgs, profileType } = userProfile;
  const drawerPaperClasses = classNames(classes.paper, !show && classes.closed);

  const isComittee = profileType.get('codigo') === 5;

  const restMenu = isComittee ? menu.slice(3) : null;

  return (
    <>
      <Drawer
        {...handlers(open, isLargeScreen, setFocused, onToggleDrawer)}
        variant={isLargeScreen ? 'permanent' : 'temporary'}
        classes={{ paper: drawerPaperClasses }}
        PaperProps={{ elevation: 3 }}
        open={show}
      >
        <div className={classes.menuContent}>
          <div className={classNames(classes.toolbar, show && classes.wrapper)}>
            <Link to="/" style={{ width: '100%' }}>
              <div className={show ? classes.bigLogo : classes.smallLogo}>
                <img
                  src={show ? '/img/logotipo.svg' : '/img/logo.svg'}
                  style={{ width: w }}
                  alt="Sua Formatura"
                />
              </div>
            </Link>
          </div>
          <Divider className={classes.wrapper} />
          <Menu
            switchDrawerFocus={setFocused}
            isFocused={isFocused}
            noDefaultIcon
            {...rest}
            menu={userMenu(userProfile, setDialogOpen)}
          />
          <Divider className={classes.wrapper} />
          <Menu
            switchDrawerFocus={setFocused}
            isFocused={isFocused}
            {...rest}
            style={isLargeScreen && !isComittee ? { height: '100%' } : null}
            menu={isComittee ? menu.slice(0, 3) : menu}
          />
          {isComittee && <Divider className={classes.wrapper} />}
          {restMenu && (
            <Menu
              style={isLargeScreen ? { marginBottom: '75px' } : null}
              switchDrawerFocus={setFocused}
              isFocused={isFocused}
              {...rest}
              menu={restMenu}
            />
          )}
          <Menu
            className={isLargeScreen ? classes.exitButton : ''}
            switchDrawerFocus={setFocused}
            isFocused={isFocused}
            {...rest}
            menu={List([
              Map({
                icon: 'ExitToApp',
                label: 'Sair',
                href: '/logout',
              }),
            ])}
          />
        </div>
      </Drawer>
      <OrganizationDialog
        numberOfOwnedOrgs={numberOfOwnedOrgs}
        onClose={() => setDialogOpen(false)}
        suspendSession={suspendSession}
        open={dialogIsOpen}
      />
    </>
  );
}

export default React.memo(withStyles(styles)(React.memo(MenuDrawer)));
