// @TODO Update: remover componente quando atualizar o Autocomplete.
import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import { Chip, TextField, Tooltip } from '@material-ui/core';
// @TODO Update: vai virar hook useMatchWidth (criar hook)
import withWidth from '@material-ui/core/withWidth';
// @TODO Update: Material UI migration hotspot - vai virar hook
import { withStyles } from '@material-ui/core/styles';
import { ellipsis } from '../core/util';

function styles(theme) {
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);
  return {
    chip: {
      marginRight: u / 4,
      marginBottom: u / 4,
    },
    inputRoot: { /* flexWrap: 'wrap', */ display: '-webkit-inline-box' },
    disabled: {
      color: theme.palette.text.disabled,
    },
    input: {
      width: 'auto',
      flexGrow: 1,
    },
  };
}

function renderValueChips(
  chips,
  onDelete,
  disabled,
  // @TODO Update: vai virar hook useMatchWidth (criar hook)
  width,
  classes,
  ellipsizeOn,
  error,
) {
  if (!chips) {
    return null;
  }

  const isSmall = width === 'xs';
  const maxSize = isSmall ? ellipsizeOn.mobile : ellipsizeOn.normal;
  return chips.map((chip) => {
    const value = chip.get('value');
    const label = chip.get('label');
    const shortened = ellipsis(label, maxSize);
    const element = (
      <Chip
        tabIndex={-1}
        label={shortened}
        variant="outlined"
        deleteIcon={<Close />}
        key={chip.get('value')}
        color={error ? 'secondary' : 'default'}
        onDelete={onDelete && !disabled ? (e) => onDelete(e, chip) : null}
        className={classNames(classes.chip, disabled && classes.disabled)}
      />
    );

    if (label !== shortened) {
      return (
        <Tooltip key={value} title={label}>
          {element}
        </Tooltip>
      );
    }
    return element;
  });
}

function renderChips(params) {
  const {
    chips,
    // @TODO Update: vai virar hook useMatchWidth (criar hook)
    width,
    classes,
    disabled,
    onDelete,
    errorChips,
    ellipsizeOn,
    onDeleteError,
  } = params;
  const vals = renderValueChips(
    chips,
    onDelete,
    disabled,
    // @TODO Update: vai virar hook useMatchWidth (criar hook)
    width,
    classes,
    ellipsizeOn,
  );
  const errs = !errorChips
    ? null
    : renderValueChips(
        errorChips,
        onDeleteError,
        disabled,
        // @TODO Update: vai virar hook useMatchWidth (criar hook)
        width,
        classes,
        ellipsizeOn,
        true,
      );

  if (!vals) {
    return errs ? errs.toArray() : null;
  }
  return errs ? vals.concat(errs).toArray() : vals.toArray();
}

function createHandleKeyDown(props) {
  return (event) => {
    const { value } = props;
    if (event.key !== 'Backspace' || Boolean(value)) {
      return;
    }

    const { chips, onDelete, errorChips, onDeleteError } = props;
    const hasErrorChips = errorChips && errorChips.size > 0;
    if (hasErrorChips) {
      const lastIdx = errorChips.size - 1;
      const last = errorChips.get(lastIdx);
      onDeleteError(event, last);
      return;
    }

    const hasChips = chips && chips.size > 0;
    if (hasChips && onDelete) {
      const lastIdx = chips.size - 1;
      const last = chips.get(lastIdx);
      onDelete(event, last);
    }
  };
}

function createOnKeyDown(InputProps = {}, handleKeyDown) {
  const { onKeyDown } = InputProps;

  if (!onKeyDown) {
    return handleKeyDown;
  }
  return (event) => {
    handleKeyDown(event);
    onKeyDown(event);
  };
}

function ChipTextField(props) {
  const {
    width,
    chips,
    classes,
    onDelete,
    InputProps,
    errorChips,
    ellipsizeOn,
    onDeleteError,
    valueSize,
    singleLine,
    type, // está aqui para não ser passado adiante
    placeholder, // está aqui para não ser passado adiante
    ...other
  } = props;
  const { disabled } = props;
  const handleKeyDown = createHandleKeyDown(props);
  const onKeyDown = createOnKeyDown(InputProps, handleKeyDown);
  const customInputProps = {
    onKeyDown,
    classes: {
      root: singleLine ? '' : classes.inputRoot,
      input: classes.input,
    },
    startAdornment: renderChips({
      chips,
      // @TODO Update: vai virar hook useMatchWidth (criar hook)
      width,
      classes,
      disabled,
      onDelete,
      valueSize,
      errorChips,
      ellipsizeOn,
      onDeleteError,
    }),
  };

  return (
    <TextField
      InputProps={{ ...InputProps, ...customInputProps }}
      onKeyDown={onKeyDown}
      {...other}
    />
  );
}

ChipTextField.defaultProps = {
  chips: null,
  disabled: false,
  errorChips: null,
  onDelete: () => null,
  onDeleteError: () => null,
  ellipsizeOn: {
    normal: 30,
    mobile: 25,
  },
};

ChipTextField.propTypes = {
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteError: PropTypes.func,
  chips: PropTypes.instanceOf(List),
  errorChips: PropTypes.instanceOf(List),
  ellipsizeOn: PropTypes.shape({
    normal: PropTypes.number.isRequired,
    mobile: PropTypes.number.isRequired,
  }),
  //
  // Props injetadas
  //
  width: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

// @TODO Update: vai virar hook useMatchWidth (criar hook)
// @TODO Update: Material UI migration hotspot - vai virar hook
export default withWidth()(withStyles(styles)(ChipTextField));
