import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ListItemIcon } from '@material-ui/core';

function styles(theme) {
  const u = theme.spacing(1);
  const { light: primary } = theme.palette.primary;
  const { white } = theme.palette.common;

  return {
    default: {
      color: '#5C93AD',
    },
    openMenu: {
      backgroundColor: primary,
      justifyContent: 'center',
      borderRadius: '50%',
      marginLeft: -u / 2,
      padding: u / 2,
      height: 4 * u,
      width: 4 * u,
      color: white,
    },
    shiftClosed: {
      marginLeft: u,
    },
    shiftOpen: {
      marginLeft: u / 2,
    },
  };
}

function ItemIcon(params) {
  const { isCurrentPage, isSubmenuOpen, drawerOpen, classes, icon } = params;
  const isCurrentTarget = isCurrentPage || isSubmenuOpen;
  const shiftClosed = !drawerOpen && !isSubmenuOpen;
  const shiftOpen = !drawerOpen && isCurrentTarget;

  return (
    <ListItemIcon
      className={classNames(
        isCurrentTarget ? classes.openMenu : classes.default,
        shiftClosed && classes.shiftClosed,
        shiftOpen && classes.shiftOpen,
      )}
    >
      {icon}
    </ListItemIcon>
  );
}

export default withStyles(styles)(ItemIcon);
