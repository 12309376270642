import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Map } from 'immutable';
import { colors } from '../theme';

const emptyTuple = Map();

const actionColors = {
  primary: colors.primary,
  secondary: colors.secondary,
  success: colors.success,
  warning: colors.warning,
  danger: colors.danger,
};

function labelText(tuple, label, tooltip) {
  if (!label) {
    return tooltip;
  }
  if (typeof label === 'function') {
    return label(tuple);
  }
  return label;
}

function linkProps(tuple, link, href) {
  if (link) {
    const destinyLink = typeof link === 'function' ? link(tuple) : link;
    if (destinyLink) {
      return {
        component: Link,
        to: destinyLink,
      };
    }
  }

  if (href) {
    return {
      href: typeof href === 'function' ? href(tuple) : href,
      component: 'a',
    };
  }
  return null;
}

/*
 * Ordena as action para as actions disabled fiquem por ultimo.
 */
function orderActions(actions, tuple) {
  return actions.sort((a, b) => {
    const disableA =
      typeof a.disabled === 'function'
        ? a.disabled(tuple || emptyTuple)
        : a.disabled;
    const disableB =
      typeof b.disabled === 'function'
        ? b.disabled(tuple || emptyTuple)
        : b.disabled;

    if (!disableA && disableB) {
      return -1;
    }
    if (disableA && !disableB) {
      return 1;
    }
    return 0;
  });
}

function filterHideActions(actions, tuple) {
  return actions.filter((action) => {
    const { hide } = action;
    if (hide && tuple) {
      return !(typeof hide === 'function' ? hide(tuple) : hide);
    }
    return true;
  });
}

function ActionMenu(props) {
  const { actions, tuple, anchor, actionParams, onClose, onExited } = props;
  if (actions.length < 1) {
    return null;
  }

  const filteredAction = filterHideActions(actions, tuple);
  const ordenedAction = orderActions(filteredAction, tuple);

  return (
    <Menu
      anchorEl={anchor}
      onClose={onClose}
      id="tableActionMenu"
      open={Boolean(anchor)}
      onExited={onExited}
    >
      {ordenedAction.map((action) => {
        const {
          disabled,
          onClick,
          tooltip,
          label,
          color,
          icon,
          link,
          href,
        } = action;

        return (
          <MenuItem
            key={tooltip}
            disabled={
              typeof disabled === 'function'
                ? disabled(tuple || emptyTuple)
                : disabled
            }
            onClick={(e) => {
              if (onClick) {
                onClick(e, tuple || emptyTuple, actionParams);
              }
              onClose();
            }}
            {...linkProps(tuple || emptyTuple, link, href)}
          >
            <ListItemIcon style={{ color: actionColors[color] }}>
              {icon}
            </ListItemIcon>
            <ListItemText
              inset
              primary={
                <Typography style={{ fontSize: '1rem' }}>
                  {labelText(tuple || emptyTuple, label, tooltip)}
                </Typography>
              }
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
}

ActionMenu.defaultProps = {
  actionParams: null,
  id: 'datatable',
  anchor: null,
  tuple: null,
};

ActionMenu.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.string,
  // parâmetros adicionais passado às ações
  // eslint-disable-next-line react/forbid-prop-types
  actionParams: PropTypes.object,
  tuple: PropTypes.instanceOf(Map),
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.instanceOf(HTMLButtonElement),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      ...IconButton.propTypes,
      // eslint-disable-next-line react/forbid-prop-types
      icon: PropTypes.any,
      link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      href: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      hide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    }),
  ).isRequired,
};

export default ActionMenu;
