import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

import { colors, theme } from "../theme";

const useStyles = makeStyles({
  mainBox: {
    display: "flex",
    justifyContent: "center",
    gap: "2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  leftBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    width: "35%",
    height: "624px",
    background: "linear-gradient(90.35deg, #0F406F 0.27%, #073055 99.67%)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      height: "auto",
      width: "100%",
      background: "transparent",
      boxShadow: "none",
    },
  },
  imageBox: {
    margin: "6rem 2rem 4rem 2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleLeftBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
    justifyContent: "space-between",
  },
  subtitle: {
    color: "#FFFFFF",
    font: "1rem",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      color: colors.veryDarkBlue,
    },
  },
  title: {
    fontSize: "2rem",
    letterSpacing: "0.12em",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    lineHeight: "37px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      color: colors.veryDarkBlue,
    },
  },
  mainTitleBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    height: "4rem",
    padding: "1rem 2rem 0 2rem",
  },
  titleLine: {
    height: "100%",
    width: "3px",
    marginRight: "1rem",
    borderRadius: "25px",
    boxShadow: "0px 2px 5px rgba(255, 152, 0, 0.25)",
  },
  rightBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    width: "60%",
    height: "624px",
    background: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      height: "auto",
      width: "100%",
      background: "transparent",
      boxShadow: "none",
    },
  },
  tileRightBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    margin: "0 0 3rem 0",

    "& h1": {
      color: colors.blueLight,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "1.4rem",
      lineHeight: "16px",
      margin: "2.8rem 0 0 2rem",

      textTransform: "uppercase",
    },
  },
  options: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
    marginBottom: "2rem",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    },
  },
  optionsBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  titleOptions: {
    padding: "1rem 2rem",
    "& h2": {
      display: "flex",
      alignItems: "center",
      margin: "0 0 1.5rem 0",
      color: colors.blueLight,
      lineHeight: "15px",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
      color: "#0093E9",
      lineHeight: "12px",
    },
  },
  button: {
    marginRight: "1.5rem",
    color: "#ffffff",
    height: "2rem",
    width: "2rem",
    minWidth: "0",
    minHeight: "0",
    borderRadius: "50%",
    background: "linear-gradient(90.35deg, #0076E5 0.27%, #009FCD 99.67%)",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    transition: "2s",
    "&:hover": {
      transition: "2s",
      background: "linear-gradient(90.35deg, #0365c0 0.27%, #038fb6 99.67%)",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.311)",
    },
  },
  dataBox: {
    display: "flex",
    height: "3rem",
  },
});

export function DoubleContainers(props) {
  const { image, imageMargin, Title, content, data } = props;

  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.leftBox}>
        <Box className={classes.imageBox} style={{ margin: imageMargin }}>
          <img src={image} alt="Imagem" />
        </Box>

        <Box className={classes.mainTitleBox}>
          {data.map((item) => (
            <Box className={classes.dataBox}>
              <Box className={classes.titleLine} style={{ backgroundColor: item.color }} />
              <Box className={classes.titleLeftBox}>
                <Box className={classes.subtitle}>{item.title}</Box>
                <Box className={classes.title}>{item.data}</Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.rightBox}>
        {Title && (
          <>
            <Box className={classes.tileRightBox}>
              <h1>{Title}</h1>
            </Box>
          </>
        )}

        <Box className={classes.optionsBox}>{content}</Box>
      </Box>
    </Box>
  );
}
