import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import MuiDrawer from '@material-ui/core/Drawer';
// @TODO Update: Material UI migration hotspot - vai virar hook
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import IconButton from './IconButton';

const NARROW_WIDTH = 384;
const MEDIUM_WIDTH = 690;
const WIDE_WIDTH = 920;

// @TODO Update: Material UI migration hotspot - vai virar hook
function styles(theme) {
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);
  const down = theme.breakpoints.down('md');

  return {
    full: {
      width: '100%',
    },
    narrow: {
      width: NARROW_WIDTH,
      [down]: {
        width: '100%',
      },
    },
    medium: {
      width: MEDIUM_WIDTH,
      [down]: {
        width: '100%',
      },
    },
    wide: {
      width: WIDE_WIDTH,
      [down]: {
        width: '100%',
      },
    },
    appBar: {
      marginBottom: u,
    },
    content: {
      padding: u * 2,
      [down]: {
        padding: u,
      },
    },
    title: {
      flexGrow: 1,
    },
    close: {
      marginRight: -2 * u,
    },
  };
}

function Drawer(props) {
  const {
    open,
    title,
    width,
    style,
    anchor,
    onClose,
    classes,
    children,
  } = props;

  return (
    <MuiDrawer
      open={open}
      style={style}
      anchor={anchor}
      onClose={onClose}
      classes={{ paper: classes[width] }}
    >
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            color="inherit"
            tooltip="Fechar"
            onClick={onClose}
            className={classes.close}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </MuiDrawer>
  );
}

Drawer.defaultProps = {
  width: 'default',
  anchor: 'right',
  children: null,
  open: false,
};

Drawer.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  width: PropTypes.oneOf(['default', 'narrow', 'medium', 'wide', 'full']),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(Drawer);
