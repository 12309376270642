// @TODO Update: - juntar este componete e o conteúno da pasta 'form' na pasta
//                 'Form'
//               - Coloca a função 'createSubmitFn' no Form/util.js
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Request } from '../core/comm';
import actions from '../core/components/form/actions';
import { FormContext } from './form';

function checkValidator(validator) {
  if (!validator || typeof validator === 'function') {
    return validator;
  }
  return validator.id;
}

function Form(props) {
  const { id } = props;
  const {
    children,
    view, // essas props só estão aqui para não
    validator, // serem passadas para o DOM.
    setValidator, // <<
    disabled,
    ...formProps
  } = props;

  React.useEffect(() => {
    setValidator(validator);
  }, [checkValidator(validator)]);
  return (
    <form {...formProps} autoComplete="off">
      <FormContext.Provider
        value={{
          view,
          form: id,
          formDisabled: disabled,
        }}
      >
        {children}
      </FormContext.Provider>
    </form>
  );
}

Form.defaultProps = {
  id: 'form',
  disabled: false,
  onSubmit: (e) => e.preventDefault(),
  validator: () => null,
};

Form.propTypes = {
  id: PropTypes.string,
  view: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  validator: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      validator: PropTypes.func,
    }),
  ]),
  disabled: PropTypes.bool,
};

function bindNewID(view, formID, data) {
  if (!data || data.length < 1) {
    return null;
  }

  const { codigo: id } = data[0];
  const st = { field: 'codigo', value: id, data: id };
  return actions.setFieldState(view, formID, st);
}

function createAppendDispatch(fn) {
  return (dispatch) => {
    if (!fn) {
      return dispatch;
    }
    if (!dispatch) {
      return List([fn]);
    }
    if (typeof dispatch === 'function') {
      return List([dispatch, fn]);
    }
    return dispatch.push(fn);
  };
}

function submitionRequest(
  request = Request(),
  bindNewID,
  invalidateCache,
  clear,
) {
  const ufn = (newDispatch) => (dispatch) => {
    if (typeof dispatch === 'function') {
      return List([newDispatch, dispatch]);
    }
    if (!dispatch) {
      return newDispatch;
    }
    return dispatch.push(newDispatch);
  };

  return request.update('onSuccess', (onSuccess) =>
    onSuccess
      .update('dispatch', List(), createAppendDispatch(invalidateCache))
      .update('dispatch', List(), createAppendDispatch(clear))
      .update('dispatchRaw', List(), ufn(bindNewID)),
  );
}

Form.createSubmitFn = (formID, submit, clearOnSuccess = false) => {
  const method = submit.get('method');
  const request = submit.get('request');
  const invalidateCache = submit.get('invalidateCache');
  if (!method || !request.get('url')) {
    return () => null;
  }

  const view = request.get('view');
  const invalidate = invalidateCache || (() => null);
  const bindID = (rawData) => bindNewID(view, formID, rawData);
  const clear = clearOnSuccess ? () => actions.clean(view, formID) : null;
  const req = submitionRequest(request, bindID, invalidate, clear);
  return () => actions.submit(formID, submit.set('request', req));
};

function mapDispatchToProps(dispatch, ownProps) {
  const { view, id = 'form' } = ownProps;
  return bindActionCreators(
    { setValidator: (validator) => actions.setValidator(view, id, validator) },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Form);
