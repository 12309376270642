import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import SubmitButton from '../form/SubmitButton';

function styles(theme) {
  const u = theme.spacing(1);
  const smDown = theme.breakpoints.down('sm');
  return {
    button: {
      minWidth: 200,
      fontWeight: 'bold',
      fontSize: 16,
      marginRight: 2 * u,
      marginLeft: 2 * u,
      padding: '8px 20px',
      [smDown]: {
        minWidth: 100,
        fontSize: 14,
      },
    },
  };
}

function ActionButton(props) {
  const {
    text,
    value,
    omit,
    classes,
    className,
    isSubmiting,
    ...others
  } = props;

  return (
    <SubmitButton
      size="small"
      align="right"
      color="light"
      variant="contained"
      loading={isSubmiting}
      className={classNames(classes.button, className)}
      {...others}
    >
      {text}
    </SubmitButton>
  );
}

function renderExtraAction(action, classes, isMobile) {
  if (!action) {
    return null;
  }

  const { value } = action;
  const buttonContent = typeof value === 'function' ? value(isMobile) : value;

  return <ActionButton classes={classes} text={buttonContent} {...action} />;
}

function ActionPanel(props) {
  const {
    communicating,
    disableSubmit,
    isSubmiting,
    submitText,
    hideSubmitButton,
    isMobile,
    classes,
    action,
    isLast,
    onClick,
    editMode,
    sideSubmit,
  } = props;

  return (
    <>
      {sideSubmit && sideSubmit()}
      {renderExtraAction(action, classes, isMobile)}
      <ActionButton
        onClick={onClick}
        classes={classes}
        isSubmiting={isSubmiting}
        text={isLast || editMode ? submitText : 'continuar'}
        disabled={communicating || disableSubmit}
        style={
          hideSubmitButton && (isLast || editMode) ? { display: 'none' } : null
        }
      />
    </>
  );
}

export default withStyles(styles)(ActionPanel);
