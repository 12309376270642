import React from 'react';
import { Chip, FormHelperText } from '@material-ui/core';
// @TODO Update: Material UI migration hotspot - vai virar hook
import { withStyles } from '@material-ui/core/styles';
import { OrderedSet } from 'immutable';
import PropTypes from 'prop-types';

function styles(theme) {
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);

  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: u / 2,
    },
  };
}

function FileList(props) {
  const { classes, onChange, value, error, helperText } = props;
  const isMultiple = OrderedSet.isOrderedSet(value);

  return (
    <div className={classes.root}>
      {!isMultiple && value && (
        <Chip
          label={value.name}
          className={classes.chip}
          onDelete={(e) => onChange(e, null)}
        />
      )}
      {isMultiple &&
        value &&
        value
          .toArray()
          .map((file) => (
            <Chip
              key={`${file.name}:${file.lastModified}:${file.size}`}
              label={file.name}
              className={classes.chip}
              onDelete={(e) => onChange(e, value.remove(file))}
            />
          ))}
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
}

FileList.defaultProps = {
  value: null,
  error: false,
  helperText: null,
};

FileList.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(OrderedSet),
    PropTypes.object,
  ]),
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(FileList);
