import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { colors } from '../../theme';

function styles(theme) {
  const u = theme.spacing(1);
  return {
    root: {
      cursor: 'pointer',
      display: 'inline',
      margin: u / 2,
      borderRadius: '50%',
      width: u,
      height: u,
      paddingLeft: u / 1.5,
      paddingRight: u / 1.5,
      color: 'white',
      textAlign: 'center',
    },
    inactive: {
      backgroundColor: 'rgb(45,45,45)',
    },
    primaryColor: {
      backgroundColor: colors.primary,
    },
    secondaryColor: {
      backgroundColor: colors.secondary,
    },
    successColor: {
      backgroundColor: colors.success,
    },
    warningColor: {
      backgroundColor: colors.warning,
    },
  };
}

function ToggleBadge(props) {
  const { active, color, onClick, classes, children } = props;
  const className = active ? classes[`${color}Color`] : classes.inactive;

  return (
    <div
      onClick={onClick}
      className={classNames(className, classes.root)}
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
}

ToggleBadge.defaultProps = {
  active: false,
  color: 'primary',
  onClick: () => null,
};

ToggleBadge.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ToggleBadge);
