import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

function TabsPanel(props) {
  const { currentTab, onChange } = props;
  const actionRef = React.useRef();
  const theme = useTheme();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      actionRef.current.updateIndicator();
    }, theme.transitions.duration.enteringScreen);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentTab]);

  return (
    <Tabs
      centered
      value={currentTab}
      onChange={onChange}
      action={actionRef}
      variant="fullWidth"
      textColor="primary"
      indicatorColor="primary"
    >
      <Tab label="Últimos" />
      <Tab label="Diário" />
      <Tab label="Mensal" />
      <Tab label="Período" />
    </Tabs>
  );
}

export default TabsPanel;
