import { List, Map } from 'immutable';

const Choices = {
  LAST: 'last',
  DAILY: 'daily',
  MONTHLY: 'monthly',
  PERIODICALLY: 'periodically',
};

const days = {
  '7days': 7,
  '15days': 15,
  '30days': 30,
};

const rangeLabel = {
  '7days': '7 dias',
  '15days': '15 dias',
  '30days': '30 dias',
};

const tabNumber = {
  [Choices.LAST]: 0,
  [Choices.DAILY]: 1,
  [Choices.MONTHLY]: 2,
  [Choices.PERIODICALLY]: 3,
};

const tabByNumber = [
  Choices.LAST,
  Choices.DAILY,
  Choices.MONTHLY,
  Choices.PERIODICALLY,
];

const lastOnesOptions = List([
  Map({ value: '7days', label: '7 Dias' }),
  Map({ value: '15days', label: '15 Dias' }),
  Map({ value: '30days', label: '30 Dias' }),
]);

export { Choices, days, rangeLabel, tabNumber, tabByNumber, lastOnesOptions };
