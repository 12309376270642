import { connect } from 'react-redux';
import { List } from 'immutable';
import { bindActionCreators } from 'redux';
import { Submit } from '../form';
import { insertInOnSuccess } from '../../core/util';
import qcomm from '../../core/comm/queries';
import form from '../../core/components/form/actions';
import qform from '../../core/components/form/queries';
import Form from '../Form';
import DialogForm from './DialogForm';

// /////////////////////////////////////////////////////////
//
// Utils
//
// /////////////////////////////////////////////////////////

/**
 * Verifica se há algum erro no relatório
 * @param {Map} errors
 */
function checkHasError(errors) {
  if (!errors || errors.isEmpty()) {
    return false;
  }
  const keys = Object.keys(errors.toObject());
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const error = errors.get(k);
    if (error) {
      // Tratamento de multifields
      if (List.isList(error)) {
        let someError = false;
        error.forEach((el) => {
          if (checkHasError(el)) {
            someError = true;
          }
        });
        return someError;
      }
      return true;
    }
  }
  return false;
}

/**
 * Adiciona comportamentos e informações ao Submit
 *
 * @param {Submit} submit
 * @param {string} view
 * @param {function} onClose
 */
function enchantSubmit(submit, view, onSubmit, onClose, clearForm) {
  if (!submit) {
    return Submit();
  }

  let enchantedSubmit = insertInOnSuccess(submit, clearForm);
  enchantedSubmit = insertInOnSuccess(enchantedSubmit, onClose);
  if (onSubmit) {
    enchantedSubmit = insertInOnSuccess(enchantedSubmit, onSubmit);
  }
  return enchantedSubmit.setIn(['request', 'view'], view);
}

// /////////////////////////////////////////////////////////
//
// Mapeamento
//
// /////////////////////////////////////////////////////////

function mapStateToProps(state, ownProps) {
  const { id = 'dialogForm', view, submit } = ownProps;

  const resource = submit && submit.getIn(['request', 'url']);
  return {
    isSubmitting: submit ? qcomm.isSending(state, view, resource) : false,
    communicating: qcomm.isCommunicating(state),
    hasError: qform.queryErrors(state, view, id, checkHasError),
  };
}

function mapDispatchToProps(dispatch, props) {
  const { id = 'dialogForm', view, submit, onSubmit, onClose } = props;
  const clearForm = () => form.clean(view, id);
  const s = enchantSubmit(submit, view, onSubmit, onClose, clearForm);
  const doSubmit = Form.createSubmitFn(id, s);

  return bindActionCreators(
    {
      clearForm,
      doSubmit,
      showErrors: (flag) => form.showErrors(view, id, flag),
    },
    dispatch,
  );
}

// /////////////////////////////////////////////////////////
//
// Exportação
//
// /////////////////////////////////////////////////////////

export default connect(mapStateToProps, mapDispatchToProps)(DialogForm);
