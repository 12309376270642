import React from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { OrderedSet } from 'immutable';
import PropTypes from 'prop-types';

function FileChooser(props) {
  const { accept, multiple, label, onChange, value } = props;
  const inputRef = React.useRef(null);
  const button = !label ? (
    <IconButton onClick={() => inputRef.current.click()}>
      <AttachFile />
    </IconButton>
  ) : (
    <Button
      style={{
        border: '1px solid',
        textTransform: 'none',
        marginLeft: 8,
        marginTop: 16,
      }}
      onClick={() => inputRef.current.click()}
    >
      <AttachFile />
      <Typography style={{ margin: '0px 16px' }}>
        <b>{label}</b>
      </Typography>
    </Button>
  );

  return (
    <>
      {button}
      <input
        type="file"
        ref={inputRef}
        accept={accept}
        multiple={multiple}
        onChange={(e) => {
          const { files } = e.target;

          inputRef.current.files = null;
          if (files.length < 1) {
            return;
          }
          if (!multiple) {
            onChange(e, files[0]);
            return;
          }

          // Adiciona apenas arquivos não repetidos.
          const arr = [];
          for (let i = 0; i < files.length; i++) {
            const valueArr = value ? value.toList() : [];
            if (!valueArr.find((file) => file.name === files[i].name)) {
              arr.push(files[i]);
            }
          }

          onChange(e, value ? value.concat(arr) : OrderedSet(arr));
        }}
        style={{ display: 'none' }}
      />
    </>
  );
}

FileChooser.defaultProps = {
  label: null,
  value: OrderedSet(),
  accept: '*/*',
  multiple: false,
};

FileChooser.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(OrderedSet),
    PropTypes.object,
  ]),
};

export default FileChooser;
