import { Box, Typography } from "@material-ui/core";
import "chartjs-plugin-labels";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { BarChart } from "./BarChart";
import { DoughnutChart } from "./DoughnutChart";
import { LineChart } from "./LineChart";
import { useStyles } from "./styles";

function getPercentage(total, fill) {
  if (typeof total !== "number" || typeof fill !== "number") {
    throw new Error("Both total and fill must be numbers");
  }

  if (total < 0 || fill < 0) {
    throw new Error("Both total and fill must be positive numbers or zero");
  }

  if (total === 0 && fill === 0) {
    return 0;
  }

  if (total === 0 && fill !== 0) {
    return 100;
  }

  const pct = Math.min(fill / total, 1) * 100;

  return Math.round(pct * 100) / 100;
}

function hasChartData(values, colors, labels) {
  return values && values.length > 0 && colors && colors.length > 0 && labels && labels.length > 0;
}

export function Chart({
  type,
  title,
  descriptionAlign,
  values,
  total,
  mainValue,
  colors,
  hoverColors,
  labels,
  isEmptyMessage,
  description,
  centerMessage,
  height,
}) {
  const classes = useStyles();
  const percentage = useMemo(
    () => getPercentage(Number(total), Number(mainValue)),
    [total, mainValue],
  );

  if (!hasChartData(values, colors, labels)) {
    return (
      <Box className={classes.chartContainer}>
        <Typography variant="h4">
          <b>Sem dados</b>
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {type === "bar" && (
        <BarChart
          classes={classes}
          title={title}
          values={values}
          descriptionAlign={descriptionAlign}
          labels={labels}
          colors={colors}
          hoverColors={hoverColors}
          description={description}
          height={height}
        />
      )}

      {type === "doughnut" && (
        <DoughnutChart
          classes={classes}
          title={title}
          values={values}
          descriptionAlign={descriptionAlign}
          labels={labels}
          colors={colors}
          hoverColors={hoverColors}
          description={description}
          mainValue={mainValue}
          percentage={percentage}
          centerMessage={centerMessage}
          isEmptyMessage={isEmptyMessage}
          height={height}
        />
      )}

      {type === "line" && (
        <LineChart
          classes={classes}
          title={title}
          values={values}
          descriptionAlign={descriptionAlign}
          labels={labels}
          description={description}
          height={height}
        />
      )}
    </>
  );
}

Chart.propTypes = {
  type: PropTypes.oneOf(["bar", "doughnut", "line"]).isRequired,
  descriptionAlign: PropTypes.oneOf(["top", "bottom", "left"]),
  values: PropTypes.arrayOf(PropTypes.number),
  total: PropTypes.number,
  mainValue: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
  hoverColors: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  isEmptyMessage: PropTypes.string,
  description: PropTypes.arrayOf(
    PropTypes.shape({
      dotColor: PropTypes.string.isRequired,
      labelDescription: PropTypes.string.isRequired,
    }),
  ),
  centerMessage: PropTypes.string,
  height: PropTypes.string,
};

Chart.defaultProps = {
  descriptionAlign: "bottom",
  values: [0],
  total: 0,
  mainValue: [0],
  colors: ["#2B819B"],
  hoverColors: null,
  labels: null,
  isEmptyMessage: "",
  description: "",
  centerMessage: "",
  height: "12rem",
};
